import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {Button as ButtonComponent} from '@unthinkable/react-button';
import {buttonStyles} from './Button.styles';

const Button = props => {
  let styles = useStyles(buttonStyles);
  return <ButtonComponent styles={styles} {...props} />;
};

export default Button;

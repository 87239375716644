import React from 'react';
import {BaseTextRender, InputLabel} from '@unthinkable/react-input';

export const TextAreaRenderer = props => {
  return (
    <InputLabel {...props}>
      <BaseTextRender whiteSpace={'pre-wrap'} {...props} />
    </InputLabel>
  );
};

export default TextAreaRenderer;

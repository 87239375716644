import {
  Text,
  Image,
  TouchableOpacity,
  Styled,
  View,
  Row,
  Platform,
} from '@unthinkable/react-core-components';

export const ChipContainer = Styled(Row).withConfig({
  shouldForwardProp: prop =>
    ![
      'maxWidth',
      'backgroundColor',
      'borderColor',
      'borderWidth',
      'borderRadius',
    ].includes(prop),
})`
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #EEEEED;
  max-width: 100%;
  border-radius:100px;
  overflow:hidden;
${({styles}) => styles?.container} 
${({maxWidth, borderColor, borderWidth, backgroundColor, borderRadius}) => {
  return {
    ...(borderColor ? {borderWidth: 1, borderColor} : void 0),
    ...(borderWidth ? {borderWidth} : void 0),
    ...(backgroundColor ? {backgroundColor} : void 0),
    ...(borderRadius ? {borderRadius} : void 0),
    ...(maxWidth ? {maxWidth} : void 0),
  };
}}
`;

export const ChipText = Styled(Text).withConfig({
  shouldForwardProp: prop => !['color'].includes(prop),
})`
font-size: 14px;
font-family: Inter-Regular;
line-height: 20px;
color:#1A1A1A;
${({styles, color}) => {
  return {
    ...styles?.text,
    ...(color ? {color} : void 0),
  };
}}
`;

export const PrefixText = Styled(ChipText).attrs({
  as: Platform.OS === 'web' ? 'span' : void 0,
})`
padding-right:2px;
${({styles, color}) => {
  return {
    ...styles?.prefix,
    ...(color ? {color} : void 0),
  };
}}`;

export const SuffixText = Styled(ChipText).attrs({
  as: Platform.OS === 'web' ? 'span' : void 0,
})`
padding-left:2px;
${({styles, color}) => {
  return {
    ...styles?.suffix,
    ...(color ? {color} : void 0),
  };
}}`;

export const ChipCrossIconContainer = Styled(TouchableOpacity)`

${({styles}) => styles?.crossContainer}
padding:4px;
margin:-4px;
padding-left:8px;
`;

export const ChipCrossIcon = Styled(Image)`
${({styles}) => styles?.crossIcon}
`;

export const AvatarChipContainer = Styled(Row).withConfig({
  shouldForwardProp: prop =>
    ![
      'maxWidth',
      'backgroundColor',
      'borderColor',
      'borderWidth',
      'variant',
      'onCross',
      'crossable',
    ].includes(prop),
})`
  align-items: center;
  padding-right: 12px;
  background-color: #E0F5FF;
  max-width: 100%;
  border-radius:100px;
  overflow:hidden;
${({
  styles,
  maxWidth,
  borderColor,
  borderWidth,
  backgroundColor,
  borderRadius,
  onCross,
  crossable,
}) => {
  return {
    ...styles?.container,
    ...(borderColor ? {borderWidth: 1, borderColor} : void 0),
    ...(borderWidth ? {borderWidth} : void 0),
    ...(backgroundColor ? {backgroundColor} : void 0),
    ...(borderRadius ? {borderRadius} : void 0),
    ...(maxWidth ? {maxWidth} : void 0),
    ...(onCross && crossable !== false ? {paddingRight: 6} : void 0),
  };
}}
`;

export const AvatarChipText = Styled(Text).withConfig({
  shouldForwardProp: prop =>
    !['color', 'size', 'fontSize', 'lineHeight'].includes(prop),
})`
  font-size: 14px;
  line-height: 20px;
  color:#1A1A1A;
  ${({styles, color, size, fontSize, lineHeight}) => {
    return {
      ...styles?.text,
      ...(color ? {color} : void 0),
      ...(size
        ? {
            fontSize: `${Math.ceil(size / 1.6)}px`,
            lineHeight: `${Math.ceil(size / 1)}px`,
          }
        : void 0),
      ...(fontSize && lineHeight
        ? {
            fontSize: `${fontSize}px`,
            lineHeight: `${lineHeight}px`,
          }
        : void 0),
    };
  }}
`;

export const RemainingValueText = Styled(Text).attrs({
  as: Platform.OS === 'web' ? 'span' : void 0,
})`
padding-left:2px;
font-size: 14px;
font-family: Inter-Regular;
line-height: 20px;
color:#1A1A1A;
${({styles}) => {
  return {
    ...styles?.remainingValue,
  };
}}`;

import React from 'react';
import {Image, TouchableOpacity} from '@unthinkable/react-core-components';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {localeSwitcherStyle} from './LocaleSwitcher.styles';
import {useLocale} from '../../hooks/useLocale';

function LocaleSwitcher() {
  const {toggleLocale, locale} = useLocale();
  const icons = useTheme('icons');
  const styles = useStyles(localeSwitcherStyle);

  return (
    <TouchableOpacity style={styles.container} onPress={toggleLocale}>
      <Image
        source={locale === 'en' ? icons?.flagUk : icons?.flagFr}
        style={{width: '100%', height: '100%'}}
      />
    </TouchableOpacity>
  );
}

export default LocaleSwitcher;

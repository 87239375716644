import React from 'react';
import {Form} from '../../../components/form';
import {useFormSubmit} from '../../../hooks';
import {
  TASKS_API_ENDPOINT,
  TASK_LIST_EVENT_SOURCE_ID,
} from '../constants/TaskConstants';

const TaskForm = props => {
  const {onSubmit} = useFormSubmit({
    uri: TASKS_API_ENDPOINT,
    eventSourceId: TASK_LIST_EVENT_SOURCE_ID,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header="Task Form"
      layoutFields={[
        {
          type: 'text',
          label: 'Task',
          field: 'task',
          required: true,
        },
        {
          type: 'textArea',
          label: 'Description',
          field: 'description',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const TaskAddForm = props => {
  return <TaskForm {...props} header="Add task" />;
};

export const TaskEditForm = props => {
  const {route} = props || {};
  const {row} = route?.params || {};
  return (
    <TaskForm
      {...props}
      header="Edit task"
      mode="edit"
      api={`/v1/tasks/${row?._id}`}
    />
  );
};

import React, {forwardRef} from 'react';
import {View} from '@unthinkable/react-core-components';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {useDraggable} from './hooks';
import {
  DraggableIcon,
  DraggableIconContainer,
  DraggableRowContainer,
} from './styles/TableStyles';
import {useMergeRefs} from '@unthinkable/react-utils';

export const DraggableRow = ({
  styles = {},
  children,
  draggable,
  draggableId,
  index,
  isDragDisabled,
}) => {
  if (draggable === true) {
    draggable = {};
  }
  const {dragOnIcon} = draggable;
  return (
    <Draggable
      isDragDisabled={isDragDisabled}
      key={draggableId}
      draggableId={draggableId}
      index={index}>
      {({draggableProps, dragHandleProps, innerRef}, snapshot) => {
        const isDragging = snapshot?.isDragging;
        return (
          <DraggableRowContainer
            ref={innerRef}
            styles={styles}
            isDragging={isDragging}
            {...draggableProps}>
            {dragOnIcon ? (
              <>
                {children}
                <DraggableIconContainer
                  styles={styles}
                  isDragging={isDragging}
                  {...dragHandleProps}>
                  <DraggableIcon styles={styles} />
                </DraggableIconContainer>
              </>
            ) : (
              <View {...dragHandleProps}>{children}</View>
            )}
          </DraggableRowContainer>
        );
      }}
    </Draggable>
  );
};

const DraggableData = ({droppableProps, innerRef, placeholder, children}) => {
  const ref = useMergeRefs(innerRef, children?.ref);
  const ChildListFooterComponent = children?.props?.ListFooterComponent;
  const ListFooterComponent = () => {
    return (
      <>
        {placeholder}
        {typeof ChildListFooterComponent === 'function'
          ? ChildListFooterComponent()
          : ChildListFooterComponent}
      </>
    );
  };
  return React.cloneElement(children, {
    ...droppableProps,
    ref,
    ListFooterComponent,
  });
};

export const DroppableWrapper = ({children, droppableId, type}) => {
  return (
    <Droppable droppableId={droppableId} type={type}>
      {typeof children === 'function'
        ? children
        : (provided, snapshot) => {
            return (
              <DraggableData {...provided} snapshot={snapshot}>
                {children}
              </DraggableData>
            );
          }}
    </Droppable>
  );
};

export const DraggableWrapper = ({
  children,
  data,
  setData,
  draggable,
  recursiveKey,
  rowKey,
}) => {
  const {onDragEnd} = useDraggable({
    data,
    setData,
    recursiveKey,
    rowKey,
    ...(draggable === true ? {} : draggable),
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <DroppableWrapper droppableId="droppable">{children}</DroppableWrapper>
    </DragDropContext>
  );
};

export const DroppableRow = forwardRef(
  ({ListFooterComponent, children, ...props}, ref) => {
    return (
      <View {...props} ref={ref}>
        {children}
        <ListFooterComponent />
      </View>
    );
  },
);

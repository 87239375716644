import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import {useAuth} from '../modules/auth/hooks/useAuth';
import {useStyles} from '@unthinkable/react-theme';
import {navigationStyle} from './styles';
import {LoadingScreen} from '../screens/LoadingScreen';

const Stack = createNativeStackNavigator();

const StackScreens = props => {
  const {screens, styles, screenOptions = {}} = props || {};
  if (!screens?.length) {
    return null;
  }
  return (
    <Stack.Group
      screenOptions={{
        contentStyle: styles?.stack,
        ...screenOptions,
      }}>
      {screens?.map((screen, index) => (
        <Stack.Screen key={index} {...screen} />
      ))}
    </Stack.Group>
  );
};

const ModalScreens = props => {
  const {screens, styles, screenOptions = {}} = props || {};
  if (!screens?.length) {
    return null;
  }
  return (
    <Stack.Group
      screenOptions={{
        headerShown: false,
        presentation: 'transparentModal',
        contentStyle: styles?.modal,
        ...screenOptions,
      }}>
      {screens?.map(
        ({component: Component, componentProps, options, ...screen}, index) => {
          return (
            <Stack.Screen key={index} {...screen}>
              {props => {
                return <Component {...props} {...componentProps} />;
              }}
            </Stack.Screen>
          );
        },
      )}
    </Stack.Group>
  );
};

const LeftMenuScreens = props => {
  const {screens, styles, screenOptions = {}} = props || {};
  if (!screens?.length) {
    return null;
  }
  return (
    <Stack.Group
      screenOptions={{
        headerShown: false,
        contentStyle: styles?.leftMenu,
        ...screenOptions,
      }}>
      {screens?.map(({component: Component, options, ...screen}, index) => {
        return (
          <Stack.Screen key={index} {...screen}>
            {props => {
              return <Component {...options} {...props} />;
            }}
          </Stack.Screen>
        );
      })}
    </Stack.Group>
  );
};

export const AppNavigator = () => {
  const {loading, isAuthenticated} = useAuth();
  const styles = useStyles(navigationStyle);
  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Stack.Navigator
      key={isAuthenticated ? 'home' : 'login'}
      initialRouteName={isAuthenticated ? 'home' : 'login'}>
      {isAuthenticated ? (
        <>
          {StackScreens({screens: PrivateRoutes.stack, styles})}
          {LeftMenuScreens({screens: PrivateRoutes.leftMenu, styles})}
          {ModalScreens({screens: PrivateRoutes.modal, styles})}
        </>
      ) : (
        <>
          {StackScreens({screens: PublicRoutes.stack, styles})}
          {LeftMenuScreens({screens: PublicRoutes.leftMenu, styles})}
          {ModalScreens({screens: PublicRoutes.modal, styles})}
        </>
      )}
    </Stack.Navigator>
  );
};

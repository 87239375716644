import React from 'react';
import {Row, Text} from '@unthinkable/react-core-components';
import {Button} from '../../../components/button';

export const TaskHeader = props => {
  const {navigation} = props || {};
  return (
    <Row
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 5,
      }}>
      <Text>Tasks</Text>
      <Button
        text="Add"
        onPress={() => {
          navigation.navigate('task-add');
        }}
      />
    </Row>
  );
};

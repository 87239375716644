import React from 'react';
import {Table} from '../../../components/table';
import useTaskActions from '../hooks/useTaskActions';
import {
  TASKS_API_ENDPOINT,
  TASK_LIST_EVENT_SOURCE_ID,
} from '../constants/TaskConstants';

const TasksColumns = [
  {
    header: 'Task',
    field: 'task',
  },
  {
    header: 'Description',
    field: 'description',
  },
];

const TaskTable = props => {
  const {navigation} = props || {};
  const {markDelete, onRowPress} = useTaskActions({navigation});
  return (
    <Table
      api={TASKS_API_ENDPOINT}
      eventSourceId={[TASK_LIST_EVENT_SOURCE_ID]}
      onRowPress={onRowPress}
      moreActions={[
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to delete this task?',
            confirmText: 'Delete',
          },
          onPress: markDelete,
        },
      ]}
      columns={TasksColumns}
    />
  );
};

export default TaskTable;

import React from 'react';
import {BaseTextRender, InputLabel} from '@unthinkable/react-input';
import {Linking, TouchableOpacity} from '@unthinkable/react-core-components';

const LinkRender = props => {
  const {value, linkText, numberOfLines, styles} = props;
  return (
    <InputLabel {...props}>
      <TouchableOpacity
        onPress={() => {
          Linking.openURL(value, '_blank');
        }}>
        <BaseTextRender
          title={value}
          styles={styles}
          numberOfLines={numberOfLines}
          value={linkText || value}
        />
      </TouchableOpacity>
    </InputLabel>
  );
};

export default LinkRender;

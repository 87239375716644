import {resolveExp} from '@unthinkable/react-utils';

export const filterData = ({data, searchValue, suggestionField}) => {
  if (Array.isArray(suggestionField)) {
    suggestionField = suggestionField[0];
  }
  if (!data || !data.length) {
    return [];
  }
  if (!searchValue || !searchValue.trim().length) {
    return data;
  }
  searchValue = searchValue.trim().toLowerCase();
  return data.filter(doc => {
    if (!searchValue || !searchValue.trim().length) {
      return true;
    }
    let value = doc;
    if (suggestionField) {
      value = doc[suggestionField];
    }
    if (value) {
      value = value.toLowerCase();
    }
    if (value.indexOf(searchValue) === 0) {
      return true;
    }
  });
};

export const getValueToDisplay = ({value, valueField, keyField, options}) => {
  if (Array.isArray(value)) {
    value = value.length ? value[0] : '';
  }
  if (options?.length) {
    if (keyField && valueField && value !== undefined && value !== null) {
      for (let option of options) {
        const optionKeyField = option[keyField];
        if (
          typeof option === 'object' &&
          (value === optionKeyField ||
            (typeof value !== 'string' &&
              JSON.stringify(value) === JSON.stringify(optionKeyField)))
        ) {
          return option[valueField];
        }
      }
    }
    return value;
  } else if (value && typeof value === 'object' && valueField) {
    return value[valueField];
  } else {
    return value;
  }
};

export const displayText = ({item, suggestionField}) => {
  if (Array.isArray(suggestionField)) {
    return suggestionField.map(display => {
      let rowText = typeof item === 'object' ? resolveExp(item, display) : item;
      if (typeof rowText === 'object') {
        rowText = JSON.stringify(rowText);
      }
      return rowText;
    });
  }
  let rowText =
    typeof item === 'object' && suggestionField
      ? resolveExp(item, suggestionField)
      : item;
  if (typeof rowText === 'object') {
    rowText = JSON.stringify(rowText);
  }
  return rowText;
};

export const getCascaderDisplayValue = ({values, cascades}) => {
  let displayValue = cascades.reduce((acc, cascade) => {
    const {field, valueField, suggestionField, keyField, options} = cascade;
    const displayText = getValueToDisplay({
      value: values?.[field],
      valueField: valueField || suggestionField,
      keyField,
      options,
    });
    if (displayText) {
      acc.push(displayText);
    }

    return acc;
  }, []);
  return displayValue.some(value => value)
    ? displayValue.join(' > ')
    : undefined;
};

import React from 'react';
import {
  Image,
  Row,
  Styled,
  Text,
  View,
} from '@unthinkable/react-core-components';

export const ActionWrapper = Styled(View)`
flex-direction:row;
justify-content: flex-end;
padding-top:8px;
gap:12px;
${({styles}) => styles?.footer}
`;

export const StepHeader = Styled(Text)`
font-size:14px;
color:#737373;
line-height:20px;
font-family: Inter-Medium;
${({styles}) => styles?.headerText}
`;

export const VerifiedIcon = Styled(Image)`
height:16px;
width:16px;
margin-left:9px
`;

export const StepItem = Styled(Row)`
align-items:center
`;

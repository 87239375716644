import React from 'react';
import {BaseTextRender, InputLabel} from '@unthinkable/react-input';
import moment from 'moment';

const DateRenderer = ({value, format = 'DD MMM YY', ...props}) => {
  if (value) {
    value = moment(value).format(format);
  }
  return (
    <InputLabel {...props}>
      <BaseTextRender {...props} value={value} />
    </InputLabel>
  );
};

export default DateRenderer;

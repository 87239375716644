import {useEffect, useMemo, useState, useCallback, useRef} from 'react';
import {useCallbackRef, useDidUpdate} from '@unthinkable/react-utils';
import {useDataFetchEvents} from './useDataFetchEvents';

export const useFetchTableData = ({
  fetch,
  data,
  api,
  fields,
  groupBy,
  filter: filterProp,
  params: paramsProp,
  addOnFilter: addOnFilterProp,
  sort: sortProp,
  aggregates: aggregatesProp,
  eventSourceId = [],
  count,
  only,
  search,
  searchFields,
  limit = 50,
  onDataError,
  encryption,
  addonGroupBy: addonGroupByProp,
  allowDynamicGrouping,
  groupRow,
  dataScope,
  ...restProps
}) => {
  let [loading, setLoading] = useState();
  let [source, setSource] = useState();

  let [state, setState] = useState();
  const [skip, setSkip] = useState(0);
  const lastFetchSequence = useRef(0);

  const filter = useMemo(() => filterProp, [JSON.stringify(filterProp)]);
  const addOnFilter = useMemo(
    () => addOnFilterProp,
    [JSON.stringify(addOnFilterProp)],
  );
  const params = useMemo(() => paramsProp, [JSON.stringify(paramsProp)]);
  const sort = useMemo(() => sortProp, [JSON.stringify(sortProp)]);
  const aggregates = useMemo(
    () => aggregatesProp,
    [JSON.stringify(aggregatesProp)],
  );
  const addonGroupBy = useMemo(
    () => addonGroupByProp,
    [JSON.stringify(addonGroupByProp)],
  );

  const setData = useCallbackRef(data => {
    setState({...state, ...data});
  });

  const loadDataAsync = async ({
    skip: currentSkip,
    limit: currentLimit = limit,
    source = 'initial',
  } = {}) => {
    if (data) {
      setState({data, aggregates});
      return;
    } else if (api) {
      try {
        if (!fetch) {
          throw new Error('fetch is not defined');
        }
        setLoading(true);
        setSource(source);

        lastFetchSequence.current++;
        let sequence = lastFetchSequence.current;

        let result = await fetch({
          uri: api,
          props: {
            fields,
            filter,
            addOnFilter,
            groupBy,
            addonGroupBy,
            sort,
            params,
            aggregates,
            count,
            only,
            search,
            searchFields,
            limit: currentLimit,
            skip: currentSkip,
            dataScope,
          },
          encryption,
        });

        // append data in result if limit is set
        if (source === 'fetchMore') {
          result.data = [...(state.data || []), ...(result.data || [])];
        }
        if (lastFetchSequence.current === sequence) {
          let newState = {...result};
          if (allowDynamicGrouping) {
            newState.dynamicGroupRow = groupRow;
          }
          setState(newState);
        }
      } catch (err) {
        onDataError && onDataError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchMore = useCallback(() => {
    if (limit !== -1 && !data && state?.data?.length) {
      let newSkip = skip + limit;
      // Check if newSkip exceeds data length
      if (newSkip <= state?.data.length) {
        setSkip(newSkip);
      }
    }
  }, [skip, setSkip, state]);

  const loadData = props => {
    loadDataAsync(props);
  };

  const reloadData = () => {
    loadData({
      limit: skip ? skip + limit : limit,
      skip: 0,
      source: 'reloading',
    });
  };

  useDataFetchEvents({eventSourceId}, reloadData);

  useEffect(() => {
    loadData();
  }, []);

  useDidUpdate(() => {
    setSkip(0);
    loadData({skip: 0});
  }, [filter, addOnFilter, params, search, addonGroupBy]);

  useDidUpdate(() => {
    if (skip) {
      loadData({skip, source: 'fetchMore'});
    }
  }, [skip]);

  useDidUpdate(() => {
    loadData();
  }, [data, api, sort, aggregates]);

  return {
    ...state,
    fetchMore,
    loading,
    loadingSource: source,
    setData,
    ...restProps,
  };
};

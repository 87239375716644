import React from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {Platform} from '@unthinkable/react-core-components';
import { AppNavigator } from './routes/AppNavigator';

function App() {
  return (
    <NavigationContainer
      onStateChange={state => {
        if (Platform.OS === 'web') document.title = 'App Name';
      }}
      // ref={navigationRef}
    >
      <AppNavigator />
    </NavigationContainer>
  );
}

export default App;

import React from 'react';
import {ActionWrapper, Container} from './styles/HeaderStyles';

export const Footer = ({actions, gap = 8, styles, ...props}) => {
  return (
    <Container gap={gap} styles={styles}>
      <ActionWrapper gap={gap} styles={styles}>
        {typeof actions === 'function' ? actions(props) : actions}
      </ActionWrapper>
    </Container>
  );
};

export const buttonStyles = ({theme: {colors, fonts}}) => {
  return {
    container: {
      backgroundColor: colors.NEUTRAL_HIGH,
    },
    disabledContainer: {
      backgroundColor: colors.OUTLINE,
    },
    text: {
      ...fonts.ACTION,
      color: colors.BACKGROUND,
    },
    disabledText: {color: colors.NEUTRAL_LOW},
  };
};

import {useMemo, useState} from 'react';
import {useFormik} from 'formik';
import {useFetchFormData} from '@unthinkable/react-data-handler';
import {useComputations} from './useComputations';

export const useForm = ({
  isDualMode,
  computations,
  beforeSubmit,
  afterSubmit,
  successMessage,
  onSubmit,
  onSubmitSuccess,
  onSubmitError,
  closeOnSubmit: closeOnSubmitProp,
  resetOnSubmit: resetOnSubmitProp,
  readOnly: formReadOnly,
  data: dataProp,
  defaultValues,
  ...props
}) => {
  if (formReadOnly && isDualMode) {
    isDualMode = false;
  }
  const {onError, onClose, _parentValues} = props;
  const [readOnly, setEditMode] = useState(isDualMode ? true : formReadOnly);

  const [submitState, setSubmitState] = useState();

  const emptyInitialValues = useMemo(() => ({}), []);

  let {data, loading} = useFetchFormData({
    ...props,
    data: dataProp,
    defaultValues,
  });

  const formContext = {
    loading,
    isDualMode,
    setEditMode,
    readOnly,
    computations,
    ...props,
  };

  const onSubmitForm = async (values, formikContext) => {
    const {
      type,
      closeOnSubmit = closeOnSubmitProp,
      resetOnSubmit = resetOnSubmitProp,
      onNext,
      setLoading,
    } = submitState || {};

    try {
      if (!onSubmit) {
        throw new Error('Form must have onSubmit function to submit.');
      }
      const {resetForm} = formikContext;
      setLoading?.(true);

      if (typeof beforeSubmit === 'function') {
        let result = await beforeSubmit({
          data: values,
          submitState,
          formContext: {...formContext, ...formikContext},
        });
        if (result?.data) {
          values = result.data;
        }
      }
      const submitResult = await onSubmit(values, formikContext);
      afterSubmit && (await afterSubmit(submitResult));
      isDualMode && setEditMode(true);
      onSubmitSuccess &&
        onSubmitSuccess(
          typeof successMessage === 'function'
            ? successMessage(submitResult)
            : successMessage,
        );

      type === 'saveAndNext' && onNext && (await onNext?.(submitResult));
      if (type === 'saveAndNew' || resetOnSubmit) {
        resetForm();
      } else if (type === 'saveAndClose' || closeOnSubmit) {
        onClose && onClose();
      }
      return submitResult;
    } catch (err) {
      console.log('!!!!!Error in submit form >>>>', err?.message);
      onSubmitError &&
        onSubmitError(err, {
          ...formikContext,
          handleSubmit,
        });
    } finally {
      setLoading?.(false);
    }
  };

  const formikProps = useFormik({
    initialValues: data || emptyInitialValues,
    onSubmit: onSubmitForm,
    enableReinitialize: true,
    validateOnMount: true,
    ...props,
  });

  useComputations({computations, _parentValues, onError}, formikProps);

  const handleSubmit = (e, submitState) => {
    if (submitState) {
      setSubmitState(submitState);
      setTimeout(_ => {
        formikProps.handleSubmit(e);
      });
    } else {
      formikProps.handleSubmit(e);
    }
  };

  return {
    ...formContext,
    ...formikProps,
    handleSubmit,
  };
};

import TaskRoutes from '../modules/task-management/TaskRoutes';
import MainRoutes from '../screens/Routes';

const leftMenuRoutes = [...MainRoutes.leftMenu];
const stackRoutes = [...TaskRoutes.stack];
const ModalRoutes = [];

export default {
  leftMenu: leftMenuRoutes,
  stack: stackRoutes,
  modal: ModalRoutes,
};

import React from 'react';
import {Form as FormComponent} from '@unthinkable/react-form';
import {useNavigation} from '@react-navigation/native';
import FieldTypes from './FieldTypes';
import {HttpAuthService} from '../../services';
import {useToast} from '@unthinkable/react-toast';

const BaseForm = props => {
  const navigation = useNavigation();
  const toast = useToast();

  const fetch = async ({uri, props}, encryption) => {
    const result = await HttpAuthService.get(uri, {...props, encryption});
    return result;
  };

  return (
    <FormComponent
      fieldTypes={FieldTypes}
      fetch={fetch}
      onClose={() => {
        navigation.goBack();
      }}
      onSubmitError={err => {
        toast({message: err.message, type: 'Error'});
      }}
      onError={err => {
        toast({message: err.message, type: 'Error'});
      }}
      onSubmitSuccess={(message = 'Data Saved.') => {
        message && toast({message});
      }}
      {...props}
    />
  );
};

const Form = props => {
  return <BaseForm {...props} />;
};
export default Form;

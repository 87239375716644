import React from 'react';
import {Table as TableComponent} from '@unthinkable/react-table';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {HttpAuthService} from '../../services';
import {tableStyles} from './Table.styles';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {useToast} from '@unthinkable/react-toast';

const Table = props => {
  const toast = useToast();
  const {colors} = useTheme();
  let styles = useStyles(tableStyles);

  const fetch = async ({uri, props}, encryption) => {
    try {
      const result = await HttpAuthService.get(uri, {...props, encryption});
      return result;
    } catch (err) {
      toast({message: err.message, type: 'Error'});
    }
  };

  return (
    <TableComponent
      {...props}
      fetch={fetch}
      styles={styles}
      borderColor={colors.OUTLINE}
      renderLoader={() => <ActivityIndicator />}
    />
  );
};

export default Table;

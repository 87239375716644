import React, {forwardRef} from 'react';
import {TextInput} from '@unthinkable/react-text-input';

const useNumberInput = ({
  value,
  onChangeValue: onChangeValueProp,
  ...props
}) => {
  const onChangeValue = (value, e) => {
    if (typeof value === 'string') {
      value = value.trim();
      if (value.length) {
        value = Number(value);
      } else {
        value = null;
      }
    }
    onChangeValueProp && onChangeValueProp(value, e);
  };

  if (typeof value === 'number' && !isNaN(value)) {
    value = value.toString();
  }
  return {
    ...props,
    value,
    onChangeValue,
  };
};

const NumberInput = forwardRef((props, ref) => {
  props = useNumberInput(props);
  return (
    <TextInput ref={ref} type="number" keyboardType={'numeric'} {...props} />
  );
});

export default NumberInput;

import {Row, Text, View, Styled} from '@unthinkable/react-core-components';

export const Container = Styled(Row)`
overflow: hidden;
align-items: center;
${props => props.styles?.container}
`;

export const ActionWrapper = Styled(Row)`
overflow: hidden;
flex: 1;
justify-content: flex-end;
${props => props.styles?.actionContainer}
`;

export const TitleContainer = Styled(View)`
flex: 1;
overflow: hidden;
`;

export const TitleText = Styled(Text)`
color: #19191A;
font-size: 16px;
line-height: 20px;
${props => props.styles?.title}
`;

export const SecondaryTitleText = Styled(Text)`
color: #787878;
font-size: 12px;
line-height: 16px;
${props => props.styles?.secondaryTitle}
`;

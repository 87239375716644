import React, {useContext} from 'react';
import {MediaQueryContext, useTheme} from '@unthinkable/react-theme';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useIntl} from 'react-intl';
import {ThemeSwitcher} from '../components/themeSwitcher';
import {LocaleSwitcher} from '../components/localeSwitcher';
import {useAuth} from '../modules/auth/hooks/useAuth';
import Profile from '../modules/user/screens/Profile';
import TaskScreen from '../modules/task-management/screens/TaskScreen';

function useHeader() {
  const {logout} = useAuth();

  const onLogout = () => {
    logout();
  };

  return {
    onLogout,
  };
}

const Home = props => {
  const [selectedMenu, setSelectedMenu] = React.useState(0);
  const {current: currentBreakpoint} = useContext(MediaQueryContext);
  const {user} = useAuth();

  const icons = useTheme('icons');
  const colors = useTheme('colors');
  const intl = useIntl();
  const {onLogout} = useHeader();

  const {logo, homeOutline, homeSolid, profileOutline, profileSolid} = icons;

  const menus = [
    {
      name: 'Tasks',
      icon: homeOutline,
      activeIcon: homeSolid,
      component: TaskScreen,
    },
    {
      name: 'Profile',
      icon: profileOutline,
      activeIcon: profileSolid,
      component: Profile,
    },
  ];
  let SelectedComponent = menus[selectedMenu].component;
  const menuItems = menus.map((menu, index) => {
    let isSelected = index === selectedMenu;
    return (
      <TouchableOpacity
        key={index}
        onPress={() => setSelectedMenu(index)}
        style={{
          height: 28,
          width: 28,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Image
          source={isSelected ? menu.activeIcon : menu.icon}
          style={{height: 20, width: 20}}
        />
      </TouchableOpacity>
    );
  });
  return (
    <View style={{flex: 1, backgroundColor: colors.BACKGROUND}}>
      <Row
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: colors.SURFACE3,
          padding: 8,
        }}>
        <Row style={{gap: 8, alignItems: 'center'}}>
          {currentBreakpoint !== 'md' ? (
            <Image source={logo} style={{height: 24, width: 24, padding: 4}} />
          ) : (
            void 0
          )}
          <Text style={{color: colors.NEUTRAL_HIGH}}>
            {intl.formatMessage({id: 'label.greetings'})} {user?.name}!
          </Text>
        </Row>
        <Row style={{gap: 8, alignItems: 'center'}}>
          {currentBreakpoint !== 'md' ? (
            <>
              <ThemeSwitcher />
              <LocaleSwitcher />
            </>
          ) : null}
          <TouchableOpacity
            style={{
              cursor: 'pointer',
              height: 48,
              paddingLeft: 10,
              paddingRight: 10,
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onPress={onLogout}>
            <Text style={{color: colors.NEUTRAL_HIGH}}>Logout</Text>
          </TouchableOpacity>
        </Row>
      </Row>
      {currentBreakpoint !== 'md' ? (
        <>
          <View style={{flex: 1}}>
            <SelectedComponent {...props} />
          </View>
          <Row
            style={{
              justifyContent: 'space-around',
              padding: 10,
              gap: 8,
              alignItems: 'center',
              backgroundColor: colors.SURFACE3,
            }}>
            {menuItems}
          </Row>
        </>
      ) : (
        <Row style={{flex: 1}}>
          <View
            style={{
              width: 48,
              padding: 10,
              gap: 8,
              alignItems: 'center',
              backgroundColor: colors.SURFACE3,
            }}>
            <Image source={logo} style={{height: 24, width: 24, padding: 4}} />
            {menuItems}
            <View style={{flex: 1}} />
            <ThemeSwitcher />
            <LocaleSwitcher />
          </View>
          <View style={{flex: 1}}>
            <SelectedComponent {...props} />
          </View>
        </Row>
      )}
    </View>
  );
};

export default Home;

import React from 'react';
import {Image, TouchableOpacity} from '@unthinkable/react-core-components';
import {useTheme, useColorMode, useStyles} from '@unthinkable/react-theme';
import {themeSwitcherStyle} from './ThemeSwitcher.styles';

function ThemeSwitcher() {
  const {colorMode, setColorMode} = useColorMode();
  const icons = useTheme('icons');
  const styles = useStyles(themeSwitcherStyle);

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => setColorMode(colorMode === 'dark' ? 'light' : 'dark')}>
      <Image
        source={colorMode === 'dark' ? icons?.moon : icons?.sun}
        style={{width: '100%', height: '100%'}}
      />
    </TouchableOpacity>
  );
}

export default ThemeSwitcher;

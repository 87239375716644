import {Text} from '@unthinkable/react-core-components';

const LinearGradientText = props => {
  const {colors, style, ...rest} = props;
  return (
    <Text
      {...rest}
      style={{
        ...style,
        WebkitBackgroundClip: 'text !important',
        WebkitTextFillColor: 'transparent',
        background: `linear-gradient(to right, ${colors.join(', ')})`,
      }}
    />
  );
};

export default LinearGradientText;

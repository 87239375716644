import React from 'react';
import TaskTable from './TaskTable';
import {TaskHeader} from '../components/TaskHeader';

const TaskScreen = props => {
  return (
    <>
      <TaskHeader {...props} />
      <TaskTable {...props} />
    </>
  );
};

export default TaskScreen;
import ChangePassword from "./screens/ChangePassword";
import ForgotPassword from "./screens/ForgotPassword";
import LoginForm from "./screens/LoginForm";
import RegisterForm from "./screens/RegisterForm";
import ResetPassword from "./screens/ResetPassword";

const stackRoutes = [
  {
    name: 'login',
    component: LoginForm,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'register',
    component: RegisterForm,
  },
  {
    name: 'change-password',
    component: ChangePassword,
  },
  {
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    name: 'reset-password',
    component: ResetPassword,
  },
];

export default {stack: stackRoutes};

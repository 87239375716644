import React from 'react';
import {BaseTextRender, InputLabel} from '@unthinkable/react-input';

const NumberRenderer = props => {
  let {value, toFixed} = props || {};
  if (value && toFixed) {
    value = value.toFixed(toFixed);
  }
  return (
    <InputLabel {...props} value={value}>
      <BaseTextRender {...props} value={value} />
    </InputLabel>
  );
};

export default NumberRenderer;

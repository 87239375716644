import React from 'react';
import {FieldArray} from 'formik';
import {resolveExp} from '@unthinkable/react-utils';
import {useFormContext} from './FormContext';

const NestedField = props => {
  const formContext = useFormContext();
  const {readOnly: formReadOnly} = formContext;
  let {field, render, children, readOnly} = props;
  return (
    <FieldArray key={field} name={field}>
      {nestedProps => {
        const value =
          (field && resolveExp(nestedProps?.form?.values, field)) || [];
        const fieldProps = {
          ...props,
          ...nestedProps,
          readOnly: formReadOnly || readOnly,
          value,
        };
        return children
          ? typeof children === 'function'
            ? children(fieldProps)
            : React.cloneElement(children, fieldProps)
          : render(fieldProps, formContext);
      }}
    </FieldArray>
  );
};

export default NestedField;

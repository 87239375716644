const avatar = '/images/Avatar.svg';
const logo = '/images/logo.png';
const sun = '/images/sun.png';
const moon = '/images/moon.png';
const flagFr = '/images/flag-french.png';
const flagUk = '/images/flag-uk.png';
const homeSolid = '/images/home-solid.png';
const homeOutline = '/images/home-outline.png';
const profileSolid = '/images/profile-solid.png';
const profileOutline = '/images/profile-outline.png';
const Cross = '/images/Cross.svg';

export default {
  avatar,
  logo,
  sun,
  moon,
  flagFr,
  flagUk,
  homeSolid,
  homeOutline,
  profileSolid,
  profileOutline,
  Cross,
};

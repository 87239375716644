import React from 'react';
import {FormBody, FormFooter, FormHeader, FormContainer} from './FormStyles';
import {TabView} from '@unthinkable/react-tabs';
import {LayoutFieldsRender, useResolveFormProps} from './FormLayout';
import {useFormContext} from './FormContext';

export const TabFormLayout = ({tabs, ...props}) => {
  const {header, footer, skipHeader, skipFooter, ...restProps} =
    useResolveFormProps(props);
  const context = useFormContext();

  const {styles} = restProps;

  let newTabs = Object.keys(tabs)?.reduce((acc, key) => {
    let {visible = true, ...tab} = tabs[key] || {};
    if (typeof visible == 'function') {
      visible = visible(context);
    }
    if (visible) {
      acc[key] = {
        ...tab,
        view: (
          <FormBody styles={styles}>
            <LayoutFieldsRender {...props} {...tab} />
          </FormBody>
        ),
      };
    }
    return acc;
  }, {});

  return (
    <>
      {header && !skipHeader ? (
        <FormHeader gap={12} styles={styles?.header} {...header} />
      ) : (
        void 0
      )}
      <FormContainer styles={styles}>
        <TabView
          tabs={newTabs}
          {...props}
          showsHorizontalScrollIndicator={false}
          styles={styles?.tabView}
        />
      </FormContainer>
      {footer?.actions && !skipFooter ? (
        <FormFooter styles={styles?.footer} {...footer} />
      ) : (
        void 0
      )}
    </>
  );
};

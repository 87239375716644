import React from 'react';
import {
  AutoComplete,
  // Cascader as CascaderComponent,
  AutoCompleteRenderer,
  MultiAutoComplete,
  MultiAutoCompleteRenderer,
  // CascaderRenderer as CascaderRendererComponent,
  // getValueToDisplay,
} from '@unthinkable/react-autocomplete';
import {FileInput, FileRenderer} from '@unthinkable/react-file-upload';
import DateInput from '@unthinkable/react-date-picker/src/DatePicker';
import {
  PasswordInput,
  PasswordRenderer,
  NumberInput,
  NumberRenderer,
  DateRenderer,
  TextAreaInput,
  TextAreaRenderer,
  TextInput,
  TextRenderer as TextRendererComponent,
  LinkRenderer,
} from '../formEditors/Editors';
import {toast} from '@unthinkable/react-toast';
import {HttpAuthService} from '../../services';
import {Checkbox} from '@unthinkable/react-checkbox';

const AutoCompleteRender = (props, ref) => {
  const fetch = async ({uri, props}, encryption) => {
    try {
      const result = await HttpAuthService.get(uri, {...props, encryption});
      return result;
    } catch (err) {
      toast({message: err.message, type: 'Error'});
    }
  };

  return <AutoComplete {...props} fetch={fetch} />;
};

const MultiAutoCompleteRender = (props, ref) => {
  const fetch = async ({uri, props}, encryption) => {
    try {
      const result = await HttpAuthService.get(uri, {...props, encryption});
      return result;
    } catch (err) {
      toast({message: err.message, type: 'Error'});
    }
  };

  return <MultiAutoComplete {...props} fetch={fetch} />;
};

export const FileInputComponent = props => {
  // let {upload, resourceUrl} = useAppStateContext();

  const upload = async file => {
    console.log('🚀 ~ upload ~ file:', file);
    try {
      const result = await HttpAuthService.upload('/upload', file);
      return result;
    } catch (err) {
      console.log('err = ', err);
      toast({message: err.message, type: 'Error'});
    }
  };

  return (
    <FileInput
      // resourceUrl={resourceUrl}
      upload={upload}
    />
  );
};

export default {
  text: {
    Editor: TextInput,
    Renderer: TextRendererComponent,
  },
  textArea: {
    Editor: TextAreaInput,
    Renderer: TextAreaRenderer,
  },
  password: {
    Editor: PasswordInput,
    Renderer: PasswordRenderer,
  },
  number: {
    Editor: NumberInput,
    Renderer: NumberRenderer,
  },
  date: {
    Editor: DateInput,
    Renderer: DateRenderer,
  },
  autoComplete: {
    Editor: AutoCompleteRender,
    Renderer: AutoCompleteRenderer,
  },
  multiAutoComplete: {
    Editor: MultiAutoCompleteRender,
    Renderer: MultiAutoCompleteRenderer,
  },
  checkbox: {
    Editor: Checkbox,
    Renderer: Checkbox,
  },
  link: {
    Editor: TextInput,
    Renderer: LinkRenderer,
  },

  file: {
    Editor: FileInputComponent,
    Renderer: FileRenderer,
  },
};

import {useCallbackRef} from '@unthinkable/react-utils';
import lodash from 'lodash';
import {v4 as uuidV4} from 'uuid';

export const useRowActions = ({
  data,
  setData,
  rowKey,
  recursiveKey,
  addNewRowInBottom,
}) => {
  const addChildRow = useCallbackRef(({row, updatedRow}) => {
    const rowId = row[rowKey];
    const newData = lodash.cloneDeep(data);
    //find the row recursively and add a row in the start of children
    const findAndAdd = data => {
      for (let i = 0; i < data.length; i++) {
        const children = data[i]?.[recursiveKey];
        if (data[i][rowKey] === rowId) {
          if (!children) {
            data[i][recursiveKey] = [];
          }
          let isNewRowExist = data[i][recursiveKey].find(
            row => row._id?.indexOf('new_') === 0,
          );
          if (!isNewRowExist) {
            let newRow = {
              isEditable: true,
              _id: 'new_' + uuidV4(),
              parentRow: row,
              ...updatedRow,
            };
            if (addNewRowInBottom) {
              data[i][recursiveKey].push(newRow);
            } else {
              data[i][recursiveKey].unshift(newRow);
            }
          }
        } else if (children) {
          findAndAdd(children);
        }
      }
    };
    findAndAdd(newData);
    setData({data: newData});
  });

  const updateRow = useCallbackRef(({row, updatedRow}) => {
    const rowId = row[rowKey];
    const newData = lodash.cloneDeep(data);
    //find the row recursively and update the row
    const findAndUpdate = data => {
      for (let i = 0; i < data.length; i++) {
        const children = data[i]?.[recursiveKey];
        if (data[i][rowKey] === rowId) {
          data[i] = updatedRow;
          return;
        } else if (children) {
          findAndUpdate(children);
        }
      }
    };
    findAndUpdate(newData);
    setData({data: newData});
  });

  const deleteRow = useCallbackRef(({row}) => {
    const rowId = row[rowKey];
    const newData = lodash.cloneDeep(data);
    //find the row recursively and delete the row
    const findAndDelete = data => {
      for (let i = 0; i < data.length; i++) {
        const children = data[i]?.[recursiveKey];
        if (data[i][rowKey] === rowId) {
          data.splice(i, 1);
          return;
        } else if (children) {
          findAndDelete(children);
        }
      }
    };
    findAndDelete(newData);
    setData({data: newData});
  });

  return {addChildRow, updateRow, deleteRow};
};

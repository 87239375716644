import React from 'react';
import {FormikProvider} from 'formik';
import {useForm, useStepForm} from './hooks';
import {FormLayout, InlineFormLayout} from './FormLayout';
import {TabFormLayout} from './TabFormLayout';
import {MultiStepFormLayout} from './StepsFormLayout';

const formLayouts = {
  form: FormLayout,
  inline: InlineFormLayout,
  tab: TabFormLayout,
};

const FormRender = ({children, render, type, LayoutComponent, context}) => {
  if (!LayoutComponent) {
    LayoutComponent = formLayouts[type || 'form'];
  }
  return (
    <FormikProvider value={context}>
      {render
        ? render(context)
        : typeof children === 'function'
        ? children(context)
        : children || <LayoutComponent {...context} />}
    </FormikProvider>
  );
};

export const Form = ({children, render, type, ...props}) => {
  const formContext = useForm(props);
  return (
    <FormRender
      children={children}
      render={render}
      type={type}
      context={formContext}
    />
  );
};

export const MultiStepForm = ({children, render, ...props}) => {
  const formContext = useStepForm(props);
  return (
    <FormRender
      children={children}
      render={render}
      context={formContext}
      LayoutComponent={MultiStepFormLayout}
    />
  );
};

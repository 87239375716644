import React from 'react';
import {InputLabel} from '@unthinkable/react-input';
import {MultipleFileRender} from './MultipleFileRender';

export const FileRenderer = ({value, ...props}) => {
  return (
    <InputLabel {...props}>
      <MultipleFileRender {...props} values={value} />
    </InputLabel>
  );
};

import React from 'react';
import {View} from '@unthinkable/react-core-components';
import {IndentedConnector, RowIndent, VerticalLine} from './styles/TableStyles';

export const RecursiveRowConnector = ({
  styles,
  level,
  connectorIndentation = 20,
  rowIndentation = 20,
  children,
  isLastChild,
  showLine,
  viewHeight = 0,
}) => {
  const topGap = styles?.topGap || 0;
  let render = children;
  if (showLine) {
    render = (
      <>
        {!isLastChild && level > 0 ? (
          <VerticalLine
            connectorIndentation={connectorIndentation}
            rowIndentation={rowIndentation}
            styles={styles}
          />
        ) : (
          void 0
        )}
        <View>
          {level > 0 ? (
            <IndentedConnector
              styles={styles}
              connectorIndentation={connectorIndentation}
              connectorHeight={(viewHeight - topGap) / 2 + topGap}
            />
          ) : (
            void 0
          )}
          {render}
        </View>
      </>
    );
  }
  return (
    <RowIndent
      rowIndentation={level > 0 ? rowIndentation : 0}
      connectorIndentation={level > 0 ? connectorIndentation : 0}>
      {render}
    </RowIndent>
  );
};

import {useCallback} from 'react';
import {useNavigation} from '@react-navigation/native';
import {HttpAuthService} from '../services';
import {useEventHandler} from '@unthinkable/react-event-handler';
import {useToast} from '@unthinkable/react-toast';

const invokeEvents = ({eventSourceId, notify}) => {
  if (typeof eventSourceId === 'string') {
    eventSourceId = [eventSourceId];
  }

  if (!eventSourceId?.length) {
    return;
  }

  const notifyEvent = (event, data) => {
    notify && notify(event, data);
  };

  let eventName = 'dataUpdated';

  eventSourceId.forEach(event => {
    notifyEvent(eventName, {id: event});
  });
};

export const useInvoke = ({
  close,
  method = 'post',
  eventSourceId = [],
  setLoading,
  throwError,
} = {}) => {
  const navigate = useNavigation();
  const {notify} = useEventHandler();
  const toast = useToast();
  return useCallback(
    async ({uri, data, eventSourceId: _eventSourceId, message}) => {
      try {
        setLoading?.(true);
        _eventSourceId = _eventSourceId || eventSourceId;
        let resp;
        if (method === 'delete') {
          resp = await HttpAuthService.delete(uri, {params: data});
        } else {
          resp = await HttpAuthService[method](uri, data);
        }
        invokeEvents({eventSourceId: _eventSourceId, notify});
        close && navigate.goBack();
        message && toast({message, type: 'Success'});
        setLoading?.(false);
        return resp;
      } catch (err) {
        if (throwError) {
          throw err;
        } else {
          toast({message: err.message, type: 'Error'});
        }
      } finally {
        setLoading?.(false);
      }
    },
    [close, navigate, method],
  );
};

import Home from './HomeScreen';

const leftMenuRoutes = [
  {
    name: 'home',
    component: Home,
  },
];

export default {
  leftMenu: leftMenuRoutes,
};

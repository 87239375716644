import React from 'react';
import {View} from '@unthinkable/react-core-components';
import ActivityIndicator from './ActivityIndicator';

const PositionActivityIndicator = ({
  position,
  positionContainerStyle,
  ...props
}) => {
  return (
    <View
      style={[
        {
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          alignItems: 'center',
          justifyContent: 'center',
          overflow: void 0,
        },
        positionContainerStyle,
        position ? {position} : void 0,
      ]}>
      <ActivityIndicator {...props} />
    </View>
  );
};

export default PositionActivityIndicator;

import React, {useState} from 'react';
import {Button, IconButton} from '@unthinkable/react-button';
import {useFormContext} from './FormContext';
import SubmitIcon from '../images/Submit.svg';
import SubmitActiveIcon from '../images/SubmitActive.svg';
import EditIcon from '../images/EditIcon.svg';
import CrossIcon from '../images/CrossIcon.svg';

const SubmitActionTypes = {
  save: {label: 'Save'},
  saveAndNew: {label: 'Save & New'},
  saveAndNext: {label: 'Save & Next'},
  saveAndClose: {label: 'Save & Close'},
};

export const SubmitButton = ({
  styles,
  type = 'saveAndClose',
  label = 'Save',
  disabled,
  disabledOnInvalid = true,
  variant,
  ...props
}) => {
  const {isValid, handleSubmit, status} = useFormContext();
  const [loading, setLoading] = useState();
  if (variant) {
    styles = styles[variant];
  }
  return (
    <Button
      styles={styles}
      text={label}
      loading={loading}
      disabled={
        disabled || (disabledOnInvalid && !isValid) || status === 'computing'
      }
      onPress={async e => {
        handleSubmit?.(e, {
          ...SubmitActionTypes[type],
          ...props,
          type,
          variant,
          setLoading,
        });
      }}
    />
  );
};

export const InlineSubmitButton = ({styles}) => {
  const context = useFormContext();
  const {isValid, dirty, isSubmitting, submitForm, resetForm, status} = context;

  const disabled = !dirty || !isValid || status === 'computing';
  const icon = disabled ? SubmitIcon : SubmitActiveIcon;
  return (
    <IconButton
      styles={styles}
      icon={icon}
      loading={isSubmitting}
      disabled={disabled}
      onPress={async e => {
        e.preventDefault();
        await submitForm?.();
        resetForm?.();
      }}
    />
  );
};

export const EditAction = ({
  styles,
  iconStyles,
  label = 'Edit',
  asIcon,
  icon,
}) => {
  const {setEditMode} = useFormContext();
  if (icon || asIcon) {
    return (
      <IconButton
        styles={iconStyles}
        onPress={() => {
          setEditMode(false);
        }}
        icon={icon || EditIcon}
      />
    );
  } else {
    return (
      <Button
        styles={styles}
        text={label}
        onPress={() => {
          setEditMode(false);
        }}
      />
    );
  }
};

export const CancelAction = ({styles, label = 'Cancel', onClose}) => {
  return (
    <Button
      styles={styles}
      onPress={() => {
        onClose();
      }}
      text={label}
    />
  );
};

export const CrossAction = ({styles, onClose, icon}) => {
  return (
    <IconButton
      styles={styles}
      onPress={() => {
        onClose();
      }}
      icon={icon || CrossIcon}
    />
  );
};

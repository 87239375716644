import React from 'react';
import {Image} from '@unthinkable/react-core-components';
import {TabItemStyled, TabItemWrapper, TabLabel} from './TabStyles';
import {useFetchCount} from '@unthinkable/react-data-handler';

export const TabItem = ({
  styles,
  selectedTab,
  onChange,
  item,
  itemWidth,
  itemMinWidth,
  fullWidth,
  index,
  isLast,
  fetch,
  params,
}) => {
  if (typeof item === 'string') {
    item = {label: item, value: item};
  }
  const {
    icon,
    label,
    value,
    textColor,
    width = itemWidth,
    minWidth = itemMinWidth,
    apiKey,
    count: itemCount,
  } = item;
  const selected = selectedTab === value;
  let count = useFetchCount({fetch, params, ...item});
  count = count || itemCount;
  return (
    <TabItemWrapper
      styles={styles}
      width={width}
      fullWidth={fullWidth}
      minWidth={minWidth}
      index={index}
      isLast={isLast}>
      <TabItemStyled
        width={width}
        fullWidth={fullWidth}
        minWidth={minWidth}
        selected={selected}
        styles={styles}
        onPress={() => {
          onChange?.(value, apiKey);
        }}>
        {icon ? <Image source={icon} resizeMode={'contain'} /> : void 0}
        {label &&
          (typeof label === 'string' ? (
            <TabLabel selected={selected} styles={styles} color={textColor}>
              {`${label} ${count !== undefined ? '(' + count + ')' : ''}`}
            </TabLabel>
          ) : (
            label
          ))}
      </TabItemStyled>
    </TabItemWrapper>
  );
};

import React, {memo, useEffect, useRef, useState} from 'react';
import {
  TableContainer,
  StyledTable,
  GroupContainer,
  GroupItemsContainer,
  StyledColumn,
  StyledHeaderAggregateColumn,
  StyledHeaderColumn,
  AbsoluteLoaderContainer,
  FetchMoreLoaderContainer,
  FixLoaderContainer,
  StyledRow,
  StyledHeader,
  StyledHeaderAggregate,
  HeaderRow,
  StyledHeaderColumnGroup,
  StyledHeaderColumnGroupText,
  FlexContainer,
  NoDataContainer,
  NoDataText,
  StyledHeaderWrapper,
} from './styles/TableStyles';
import {useColumns, useExpand, useRowActions, useSelection} from './hooks';
import {
  Platform,
  Row,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {Column, ColumnAggregate, ColumnHeader} from './Column';
import {resolveExp, useCallbackRef} from '@unthinkable/react-utils';
import {useResolveProps} from './hooks/useResolveProps';
import {useFetchTableData} from '@unthinkable/react-data-handler';
import {
  DraggableRow,
  DraggableWrapper,
  DroppableRow,
  DroppableWrapper,
} from './Draggable';
import {RecursiveRowConnector} from './RecursiveRowConnector';
import {GroupRow} from './GroupRow';
import lodash from 'lodash';

const RenderRow = memo(
  props => {
    const {
      item: row,
      index,
      level = 0,
      isLastChild,
      styles,
      renderRow,
      renderEditRow,
      columns,
      getColumnProps,
      rowSelectionInfo,
      renderExpandRow,
      borderColor,
      borderWidth,
      rowWidth,
      variant,
      skipRowBorder,
      onRowPress,
      toggleExpand,
      rowExpanded,
      rowKey,
      recursive,
      onRowLayout,
      isActive,
      addChildRow,
      updateRow,
      deleteRow,
    } = props;

    const rowProps = {
      styles,
      row,
      index,
      level,
      recursive,
      rowExpanded,
      toggleRowExpand: () => toggleExpand('row', rowKey),
      isLastChild,
      addChildRow: ({data} = {}) => addChildRow({row, updatedRow: data}),
      updateRow: updatedRow => updateRow({row, updatedRow}),
      deleteRow: () => deleteRow({row}),
      ...rowSelectionInfo,
    };
    const {isSelected} = rowSelectionInfo;

    let colSpanColumn = void 0;

    let modifiedColumns = columns.map(column => {
      let {type} = column;
      if (type && typeof type === 'object') {
        type = type.field ? resolveExp(row, type.field) : void 0;
      }
      let {colspan, ...restColumn} = getColumnProps(
        {
          ...column,
          type,
        },
        {row, index},
      );
      if (colspan && typeof colspan === 'function') {
        colspan = colspan({row, index});
      }
      if (colspan > 1) {
        colSpanColumn = {colspan: colspan - 1, column: restColumn};
      } else if (colSpanColumn) {
        if (colSpanColumn.column.width && restColumn.width) {
          colSpanColumn.column.width += restColumn.width;
        }
        restColumn.display = 'none'; // TODO handling for react-native
        colSpanColumn.colspan -= 1;
        if (colSpanColumn.colspan === 0) {
          colSpanColumn = void 0;
        }
      }
      return restColumn;
    });

    let render = (
      <TouchableOpacity
        key={rowKey}
        onLayout={
          onRowLayout
            ? e => {
                onRowLayout(e);
              }
            : void 0
        }
        onPress={
          onRowPress
            ? () => {
                onRowPress({row, rowIndex: index});
              }
            : void 0
        }>
        {renderRow ? (
          renderRow(rowProps)
        ) : row?.isEditable && renderEditRow ? (
          renderEditRow(rowProps)
        ) : (
          <>
            <StyledRow
              styles={styles}
              index={index}
              level={level}
              skipRowBorder={skipRowBorder}
              variant={variant}
              borderColor={borderColor}
              borderWidth={borderWidth}
              rowWidth={rowWidth}
              isSelected={isSelected}
              isActive={isActive}>
              {modifiedColumns.map((column, colIndex) => {
                let {
                  width,
                  minWidth,
                  align,
                  maxWidth,
                  display,
                  ...columnProps
                } = column;
                return (
                  <StyledColumn
                    key={colIndex}
                    styles={styles}
                    variant={variant}
                    borderColor={borderColor}
                    borderWidth={borderWidth}
                    index={colIndex}
                    align={align}
                    width={width}
                    minWidth={minWidth}
                    maxWidth={maxWidth}
                    display={display}>
                    <Column
                      getColumnProps={getColumnProps}
                      {...rowProps}
                      {...columnProps}
                    />
                  </StyledColumn>
                );
              })}
            </StyledRow>
            {rowExpanded && renderExpandRow
              ? renderExpandRow(rowProps)
              : void 0}
          </>
        )}
      </TouchableOpacity>
    );

    return render;
  },
  (prevProps, nextProps) => {
    let renderKeys = [];
    Object.keys(prevProps).forEach(key => {
      if (!lodash.isEqual(prevProps[key], nextProps[key])) {
        renderKeys.push(key);
      }
    });
    return renderKeys.length === 0;
  },
);

const RenderRowWrapper = ({isExpanded, draggable, ...props}) => {
  const {
    item: row,
    parentKey,
    id,
    index,
    styles,
    level = 0,
    isLastChild,
    toggleExpand,
    recursiveKey,
    recursiveRowIndent,
    showLine,
    connectorIndentation,
    rowIndentation,
    rowSelectionInfo,
    isRowActive,
    rowKey,
  } = props;

  const [rowHeight, setRowHeight] = useState(0);

  const uniqueKey =
    row?.[rowKey] || `${parentKey ? parentKey + '_' : ''}${id || index}`;
  const recursiveRowExpanded = isExpanded('recursive', uniqueKey);
  const recursiveChildren = row[recursiveKey];
  const recursiveToggleExpand = useCallbackRef(() =>
    toggleExpand('recursive', uniqueKey),
  );
  const recursive = {
    expanded: recursiveRowExpanded,
    toggleExpand: recursiveToggleExpand,
    hasChildren: recursiveChildren?.length ? true : false,
  };

  const {isSelected, isRowIndeterminate, toggleSelection} = rowSelectionInfo;

  const onRowLayout = useCallbackRef(e => {
    if (e?.nativeEvent?.layout?.height) {
      const newHeight = e.nativeEvent.layout.height;
      if (newHeight !== rowHeight) {
        setRowHeight(newHeight);
      }
    }
  });

  let render = (
    <RenderRow
      {...props}
      recursive={recursive}
      onRowLayout={onRowLayout}
      isActive={isRowActive?.(row)}
      rowSelectionInfo={{
        isSelected: isSelected(row, index),
        toggleSelection,
        indeterminate: isRowIndeterminate(row, index),
      }}
    />
  );

  if (recursiveChildren?.length) {
    let dataRender =
      recursiveRowExpanded &&
      recursiveChildren?.map((childRow, childIndex) => {
        return (
          <RenderRowWrapper
            {...{
              ...props,
              item: childRow,
              index: childIndex,
              level: level + 1,
              parentKey: uniqueKey,
              isLastChild: recursiveChildren.length - 1 === childIndex,
              isExpanded,
              recursive,
              draggable,
            }}
          />
        );
      });
    if (draggable) {
      dataRender = (
        <DroppableWrapper
          droppableId={row?.[rowKey]}
          type={'ITEM' + row?.[rowKey]}>
          <DroppableRow>{dataRender}</DroppableRow>
        </DroppableWrapper>
      );
    }

    render = (
      <>
        {render}
        {dataRender}
      </>
    );
  }

  if (draggable) {
    render = (
      <DraggableRow
        styles={styles}
        draggable={draggable}
        draggableId={uniqueKey}
        isDragDisabled={recursiveChildren?.length && recursiveRowExpanded}
        index={index}>
        {render}
      </DraggableRow>
    );
  }

  if (recursiveRowIndent) {
    render = (
      <RecursiveRowConnector
        viewHeight={rowHeight}
        level={level}
        styles={styles}
        isLastChild={isLastChild}
        showLine={showLine}
        connectorIndentation={connectorIndentation}
        rowIndentation={rowIndentation}>
        {render}
      </RecursiveRowConnector>
    );
  }
  return render;
};

const Table = ({
  styles = {},
  rowKey = '_id',
  loading: loadingProp,
  loadingSource: loadingSourceProp,
  onRowPress,
  recursiveKey = 'children',
  recursiveRowIndent,
  rowIndentation,
  connectorIndentation,
  showLine,
  defaultExpanded,
  exclusiveExpand,
  renderExpandRow,
  bounces,
  removeClippedSubviews,
  keyboardShouldPersistTaps,
  showsVerticalScrollIndicator,
  showsHorizontalScrollIndicator,
  ListFooterComponent,
  extraData,
  onScroll: onScrollProp,
  onEndReachedThreshold = 0.8,
  maxHeight,
  height,
  variant = 'none',
  skipRowBorder,
  borderColor,
  borderWidth,
  draggable,
  autoHeight = true,
  skipNoData,
  renderNoData,
  noDataText = 'No Data Found.',
  numColumns,
  isRowActive,
  addNewRowInBottom,
  ...props
}) => {
  props = useResolveProps(props);
  const {
    renderHeader,
    renderFooter,
    renderTableHeader,
    renderTableFooter,
    renderLoader,
    renderRow,
    renderEditRow,
    selection,
    columnStyles,
    columnFormats,
    columnRenders,
    allowDynamicGrouping,
  } = props;
  const ref = useRef(null);
  const headerRef = useRef(null);
  const headerAggregateRef = useRef(null);

  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const [offsetWidth, setOffsetWidth] = useState(0);
  const {
    data,
    aggregates,
    fetchMore,
    loading = loadingProp,
    loadingSource = loadingSourceProp,
    setData,
    dynamicGroupRow,
  } = useFetchTableData(props);

  const groupRow = allowDynamicGrouping ? dynamicGroupRow : props.groupRow;

  const {columns, headerColumns, rowWidth} = useColumns({
    ...props,
    disableHeaderSelection: !!groupRow,
    offsetWidth,
  });

  const {toggleExpand, isExpanded} = useExpand({
    defaultExpanded,
    exclusiveExpand,
  });

  const rowSelectionInfo = useSelection({
    rowKey,
    data,
    recursiveKey,
    ...(selection === true ? {} : selection),
  });

  const {addChildRow, updateRow, deleteRow} = useRowActions({
    data,
    setData,
    rowKey,
    recursiveKey,
    addNewRowInBottom,
  });
  const renderProps = {...props, ...rowSelectionInfo, data, aggregates};

  useEffect(() => {
    if (Platform.OS === 'web' && ref.current) {
      if (ref.current.scrollHeight > ref.current.clientHeight) {
        if (!scrollBarWidth) {
          setScrollBarWidth(ref.current.offsetWidth - ref.current.clientWidth);
        }
      } else if (scrollBarWidth) {
        setScrollBarWidth(0);
      }
    }
  }, [data]);

  const getColumnProps = useCallbackRef(
    ({type, formatOptions, ...column} = {}, rowProps = {}) => {
      const render = type && columnRenders?.[type];
      const style = type && columnStyles?.[type];
      const formatInfo = type && columnFormats?.[type];
      if (formatOptions && typeof formatOptions === 'function') {
        formatOptions = formatOptions(rowProps);
      }
      if (formatInfo?.formatOptions && formatOptions) {
        formatOptions = {
          ...formatInfo.formatOptions,
          ...formatOptions,
        };
      }

      return {
        ...style,
        ...formatInfo,
        type,
        formatOptions,
        render,
        ...column,
      };
    },
  );

  const _onRowPress = useCallbackRef(({row, rowIndex}) => {
    onRowPress && onRowPress({row, rowIndex});
  });

  const renderItem = ({item: row, index, id, expanded}) => {
    return (
      <RenderRowWrapper
        item={row}
        index={index}
        id={id}
        styles={styles}
        renderRow={renderRow}
        renderEditRow={renderEditRow}
        draggable={draggable}
        columns={columns}
        getColumnProps={getColumnProps}
        rowSelectionInfo={rowSelectionInfo}
        renderExpandRow={renderExpandRow}
        recursiveKey={recursiveKey}
        rowKey={rowKey}
        borderColor={borderColor}
        borderWidth={borderWidth}
        rowWidth={!groupRow ? rowWidth : void 0}
        variant={variant}
        skipRowBorder={skipRowBorder}
        onRowPress={_onRowPress}
        toggleExpand={toggleExpand}
        isExpanded={isExpanded}
        rowExpanded={expanded}
        recursiveRowIndent={recursiveRowIndent}
        connectorIndentation={connectorIndentation}
        showLine={showLine}
        rowIndentation={rowIndentation}
        isRowActive={isRowActive}
        addChildRow={addChildRow}
        updateRow={updateRow}
        deleteRow={deleteRow}
      />
    );
  };

  const renderColumnAggregates = ({fixed, aggregates}) => {
    const hasAggregate = columns?.some(column => column.aggregate);
    return hasAggregate ? (
      <StyledHeaderAggregate styles={styles}>
        <HeaderRow
          styles={styles}
          ref={headerAggregateRef}
          variant={variant}
          borderColor={borderColor}
          borderWidth={borderWidth}>
          {columns.map((column, i) => {
            const {width, minWidth, align, ...columnProps} =
              getColumnProps(column);
            return (
              <StyledHeaderAggregateColumn
                key={i}
                styles={styles}
                align={align}
                width={width}
                minWidth={minWidth}
                variant={variant}
                borderColor={borderColor}
                borderWidth={borderWidth}>
                <ColumnAggregate
                  styles={styles}
                  column={columnProps}
                  aggregates={aggregates}
                />
              </StyledHeaderAggregateColumn>
            );
          })}
        </HeaderRow>
        {fixed ? <View style={{width: scrollBarWidth}} /> : void 0}
      </StyledHeaderAggregate>
    ) : null;
  };

  const renderHeaderColumns = ({fixed, ...props} = {}) => {
    const hasHeader = columns?.some(column => column.header);
    if (!hasHeader) {
      return null;
    }
    const renderColumns = (columns, level = 0) => {
      return columns.map((column, i) => {
        let {width, minWidth, header, align, children, ...columnProps} =
          getColumnProps(column);
        if (typeof header === 'function') {
          header = header(props);
        }

        if (typeof header === 'string') {
          header = {label: header};
        }
        let {render = ColumnHeader, ...restHeader} = header || {};
        if (children?.length) {
          return (
            <View>
              <StyledHeaderColumnGroup
                key={i}
                styles={styles}
                index={i}
                variant={variant}
                borderColor={borderColor}
                borderWidth={borderWidth}>
                <StyledHeaderColumnGroupText styles={styles}>
                  {header.label}
                </StyledHeaderColumnGroupText>
              </StyledHeaderColumnGroup>
              <Row>{renderColumns(children, level + 1)}</Row>
            </View>
          );
        } else {
          return (
            <StyledHeaderColumn
              key={i}
              styles={styles}
              variant={variant}
              borderColor={borderColor}
              borderWidth={borderWidth}
              width={width}
              minWidth={minWidth}
              index={i}
              level={level}
              align={align}>
              {render
                ? render({
                    styles,
                    ...rowSelectionInfo,
                    ...columnProps,
                    ...restHeader,
                  })
                : void 0}
            </StyledHeaderColumn>
          );
        }
      });
    };

    return (
      <StyledHeader styles={styles}>
        <HeaderRow
          styles={styles}
          ref={headerRef}
          variant={variant}
          borderColor={borderColor}
          borderWidth={borderWidth}>
          {renderColumns(headerColumns)}
        </HeaderRow>
        {fixed ? <View style={{width: scrollBarWidth}} /> : void 0}
      </StyledHeader>
    );
  };

  const renderHeaderColumnsAndAggregates = ({data, aggregates, fixed}) => {
    return (
      <StyledHeaderWrapper styles={styles}>
        {renderHeaderColumns({data, aggregates, fixed})}
        {renderColumnAggregates({aggregates, fixed})}
      </StyledHeaderWrapper>
    );
  };

  const toggleGroupExpand = useCallbackRef(({index}) => {
    toggleExpand('group', index, groupRow);
  });

  const renderGroupRow = ({item: row, index}) => {
    const expanded = isExpanded('group', index, groupRow);
    let {expandable = true, header, render, hideRow} = groupRow || {};
    if (typeof hideRow === 'function') {
      hideRow = hideRow({row});
    }
    const groupData = resolveExp(row, groupRow.data);
    const aggregates = resolveExp(row, groupRow.aggregate || 'aggregates');
    const groupRowProps = {
      styles,
      row,
      index,
      groupRow,
      expanded,
      toggleExpand: toggleGroupExpand,
    };

    return (
      <GroupContainer styles={styles} rowWidth={rowWidth}>
        {hideRow ? (
          void 0
        ) : render ? (
          render(groupRowProps)
        ) : (
          <GroupRow {...groupRowProps} />
        )}
        {hideRow || expandable === false || expanded ? (
          <GroupItemsContainer styles={styles}>
            {header
              ? renderHeaderColumnsAndAggregates({
                  data: groupData,
                  aggregates,
                })
              : void 0}
            {groupData?.map((dataRow, dataRowIndex) => {
              return renderItem({
                item: dataRow,
                index: dataRowIndex,
                id: `${index}_${dataRowIndex}`,
                expanded,
              });
            })}
          </GroupItemsContainer>
        ) : (
          void 0
        )}
      </GroupContainer>
    );
  };

  const fetchMoreLoader = () => {
    return renderLoader && loading && loadingSource === 'fetchMore' ? (
      <FetchMoreLoaderContainer>{renderLoader()}</FetchMoreLoaderContainer>
    ) : (
      void 0
    );
  };

  const loader = ({fixLoader} = {}) => {
    const LoaderContainer = fixLoader
      ? FixLoaderContainer
      : AbsoluteLoaderContainer;
    return renderLoader &&
      loading &&
      loadingSource !== 'reloading' &&
      loadingSource !== 'fetchMore' ? (
      <LoaderContainer>{renderLoader()}</LoaderContainer>
    ) : (
      void 0
    );
  };

  const onScroll = event => {
    onScrollProp && onScrollProp(event);
    if (Platform.OS === 'web') {
      if (headerRef.current) {
        headerRef.current.scrollLeft = event.scrollLeft;
      }
      if (headerAggregateRef.current) {
        headerAggregateRef.current.scrollLeft = event.scrollLeft;
      }
    }
  };

  const onLayout = e => {
    if (e?.nativeEvent?.layout?.width) {
      const newOffsetWidth = e.nativeEvent.layout.width;
      if (newOffsetWidth !== offsetWidth) {
        setOffsetWidth(newOffsetWidth);
      }
    }
  };

  const CustomRenderNoData = () => {
    return (
      <NoDataContainer autoHeight={autoHeight}>
        {renderNoData ? renderNoData() : <NoDataText>{noDataText}</NoDataText>}
      </NoDataContainer>
    );
  };

  let dataRender = (
    <StyledTable
      ref={ref}
      styles={styles}
      onScroll={onScroll}
      keyExtractor={(row, index) => row[rowKey] || index.toString()}
      data={data}
      renderItem={groupRow ? renderGroupRow : renderItem}
      extraData={extraData}
      onEndReachedThreshold={onEndReachedThreshold}
      onLayout={onLayout}
      onEndReached={fetchMore}
      bounces={bounces}
      ListFooterComponent={ListFooterComponent}
      removeClippedSubviews={removeClippedSubviews}
      keyboardShouldPersistTaps={keyboardShouldPersistTaps}
      showsVerticalScrollIndicator={showsVerticalScrollIndicator}
      showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
      numColumns={numColumns}
    />
  );
  if (draggable) {
    dataRender = (
      <DraggableWrapper
        draggable={draggable}
        data={data}
        setData={setData}
        recursiveKey={recursiveKey}
        rowKey={rowKey}>
        {dataRender}
      </DraggableWrapper>
    );
  }

  let tableComponent = (
    <TableContainer
      styles={styles}
      variant={variant}
      maxHeight={maxHeight}
      autoHeight={autoHeight}
      borderColor={borderColor}
      borderWidth={borderWidth}>
      {renderTableHeader?.(renderProps)}
      {!groupRow?.header
        ? renderHeaderColumnsAndAggregates({data, aggregates, fixed: true})
        : void 0}
      {!skipNoData && !data?.length && loading === false ? (
        <CustomRenderNoData />
      ) : (
        dataRender
      )}
      {fetchMoreLoader()}
      {renderTableFooter?.(renderProps)}
    </TableContainer>
  );

  return (
    <>
      {renderHeader?.(renderProps)}
      <FlexContainer
        height={height}
        maxHeight={maxHeight}
        autoHeight={autoHeight}>
        {tableComponent}
        {loader({fixLoader: autoHeight && !data?.length})}
      </FlexContainer>
      {renderFooter?.(renderProps)}
    </>
  );
};

export default Table;

import React from 'react';
import {TouchableOpacity, Platform} from '@unthinkable/react-core-components';
import {
  DropdownInput,
  DropdownInputContainer,
  RowCreateContainer,
  RowCreateLabel,
  RowCreateValue,
  HeaderContainer,
  HeaderText,
  DropDownList,
  RowContainer,
  RowPrimaryText,
  RowSecondaryText,
  RowSeparator,
  RowContent,
  CascadingIcon,
} from './Styles';
import {displayText} from './Utility';
import CaretRight from '../images/CaretRight.svg';
import {useSelectorFetchSuggestions} from './hooks';
import {Avatar} from '@unthinkable/react-avatar';
import {Chip} from '@unthinkable/react-chip';
import {resolveExp} from '@unthinkable/react-utils';

const CreateRow = ({styles, value, onCreate}) => {
  if (!value) {
    return null;
  }
  let preservedProps;
  if (Platform.OS === 'web') {
    preservedProps = {
      onMouseDown: e => {
        e.preventDefault();
        onCreate(e);
      },
    };
  } else {
    preservedProps = {
      onPress: e => {
        onCreate(e);
      },
    };
  }
  return (
    <RowCreateContainer styles={styles} {...preservedProps}>
      <RowCreateValue styles={styles}>{value}</RowCreateValue>
      <RowCreateLabel styles={styles}>Create</RowCreateLabel>
    </RowCreateContainer>
  );
};

export const SuggestionRow = ({
  styles,
  item,
  suggestionField,
  secondarySuggestionField,
  titleField,
  colorField,
  resolveColor,
  isCascade,
  avatar,
  showChip,
}) => {
  const {caretRight = CaretRight} = styles || {};
  let rowText = displayText({item, suggestionField});
  let rowSecondaryText = displayText({
    item,
    suggestionField: secondarySuggestionField,
  });
  let title = void 0;
  if (titleField) {
    title = displayText({item, suggestionField: titleField});
  }
  let colorValue = colorField && item ? resolveExp(item, colorField) : void 0;
  if (typeof resolveColor === 'function') {
    colorValue = resolveColor(colorValue);
  }
  if (typeof colorValue === 'string') {
    colorValue = {backgroundColor: colorValue};
  }

  const {
    backgroundColor,
    textColor,
    secondaryTextColor,
    chip: chipColors,
    avatar: avatarColors,
  } = colorValue || {};

  return (
    <RowContainer styles={styles} gap={8} backgroundColor={backgroundColor}>
      {avatar ? <Avatar value={rowText} {...avatarColors} /> : void 0}
      {showChip ? (
        <Chip borderRadius={4} value={rowText} {...chipColors} />
      ) : (
        <RowContent styles={styles}>
          <RowPrimaryText
            numberOfLines={1}
            styles={styles}
            color={textColor}
            title={title}>
            {rowText}
          </RowPrimaryText>
          {secondarySuggestionField && (
            <RowSecondaryText
              numberOfLines={1}
              styles={styles}
              color={secondaryTextColor}>
              {rowSecondaryText}
            </RowSecondaryText>
          )}
        </RowContent>
      )}
      {isCascade ? (
        <CascadingIcon source={caretRight} styles={styles} />
      ) : (
        void 0
      )}
    </RowContainer>
  );
};

const SearchInput = ({
  styles,
  placeholder = 'Search',
  value,
  onChangeValue,
}) => {
  return (
    <DropdownInputContainer styles={styles}>
      <DropdownInput
        styles={styles}
        placeholder={placeholder}
        value={value}
        onChangeValue={onChangeValue}
      />
    </DropdownInputContainer>
  );
};

const SelectorList = ({
  styles,
  renderHeader,
  suggestionHeader,
  onCreateSuggestion,
  renderSuggestion = SuggestionRow,
  onSelect,
  renderSeparator: renderSeparatorProp,
  showsVerticalScrollIndicator,
  keyboardShouldPersistTaps = 'always',
  keyExtractor,
  searchPlaceholder,
  ...props
}) => {
  const suggestionProps = useSelectorFetchSuggestions(props);
  const {data, searching, searchValue, setSearchValue} = suggestionProps;

  const renderCreateRow = () => {
    return (
      <CreateRow
        styles={styles}
        value={searchValue}
        onCreate={onCreateSuggestion}
        onSelect={onSelect}
      />
    );
  };

  const renderRow = ({item, index}) => {
    let rowProps =
      Platform.OS === 'web'
        ? {
            onMouseDown: e => {
              e.preventDefault();
              onSelect({item, index}, e);
            },
          }
        : {
            onPress: e => {
              onSelect({item, index}, e);
            },
          };

    let rowComponent = renderSuggestion({
      styles,
      item,
      index,
      ...props,
    });
    return (
      <TouchableOpacity
        onMouseMove={e => {
          e?.preventDefault?.();
        }}
        {...rowProps}>
        {rowComponent}
      </TouchableOpacity>
    );
  };

  const renderSeparator = rowID => {
    return <RowSeparator key={`spr_${rowID}`} styles={styles} />;
  };

  const ListHeaderComponent = () => {
    return (
      <HeaderContainer styles={styles}>
        <HeaderText styles={styles}>{suggestionHeader}</HeaderText>
      </HeaderContainer>
    );
  };

  return (
    <>
      {renderHeader ? renderHeader(suggestionProps) : void 0}
      {searching ? (
        <SearchInput
          styles={styles}
          placeholder={searchPlaceholder}
          value={searchValue}
          onChangeValue={setSearchValue}
        />
      ) : (
        void 0
      )}
      <DropDownList
        styles={styles}
        data={data}
        renderItem={renderRow}
        horizontal={false}
        keyExtractor={keyExtractor}
        keyboardShouldPersistTaps={keyboardShouldPersistTaps}
        showsVerticalScrollIndicator={showsVerticalScrollIndicator}
        ItemSeparatorComponent={renderSeparatorProp || renderSeparator}
        ListHeaderComponent={suggestionHeader ? ListHeaderComponent : null}
        ListFooterComponent={onCreateSuggestion ? renderCreateRow : null}
      />
    </>
  );
};

export default SelectorList;

import React, {useState} from 'react';
import {TabBarRow} from './TabBar';
import {TabContainer} from './TabStyles';

export const TabView = ({
  styles,
  tabs,
  tabItemWidth,
  fullWidth,
  actions,
  headerComponent,
  footerComponent,
  selectedTab: propSelectedTab,
  onSelectTab,
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState(
    propSelectedTab || Object.keys(tabs)[0],
  );

  const selectedTabId =
    props?.isLinkTab && (props?.params?.tabId || props?.route?.params?.tabId);

  const selectedTabInfo = tabs[selectedTabId || selectedTab];
  if (typeof actions === 'function') {
    actions = actions({selectedTab: selectedTabId || selectedTab});
  }

  const {
    actions: tabActions,
    view,
    headerComponent: tabHeaderComponent,
    footerComponent: tabFooterComponent,
  } = selectedTabInfo || {};

  if (tabActions) {
    actions = actions ? [...actions, ...tabActions] : [...tabActions];
  }

  return (
    <TabContainer>
      {headerComponent ? headerComponent : void 0}
      <TabBarRow
        styles={styles}
        tabs={tabs}
        selectedTab={selectedTabId || selectedTab}
        onSelectTab={onSelectTab || setSelectedTab}
        itemWidth={tabItemWidth}
        fullWidth={fullWidth}
        actions={actions}
        {...props}
      />
      <TabContainer key={selectedTabId || selectedTab}>
        {tabHeaderComponent ? tabHeaderComponent : void 0}
        {view ? React.cloneElement(view, props) : void 0}
        {tabFooterComponent ? tabFooterComponent : void 0}
      </TabContainer>
      {footerComponent ? footerComponent : void 0}
    </TabContainer>
  );
};

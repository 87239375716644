import React from 'react';
import {useIntl} from 'react-intl';
import {View, Text, TextInput} from '@unthinkable/react-core-components';
import { loginFormStyle } from '../styles/LoginForm.styles';

import {useLogin} from '../hooks/useLogin';
import {Button} from '../../../components/button';

function LoginForm(props) {
  const {navigation} = props || {};
  const intl = useIntl();
  const {formValues, setFormValues, onLogin, loading} = useLogin();
  const {email, password} = formValues || {};
  const isLoginActionDisabled = !email?.trim() || !password?.trim() || loading;
  return (
    <View style={loginFormStyle.container}>
      <View>
        <Text style={loginFormStyle.header}>
          {intl.formatMessage({id: 'label.welcome'})}
        </Text>
        <TextInput
          style={loginFormStyle.input}
          placeholder={intl.formatMessage({id: 'label.userEmail'})}
          onChangeText={value => {
            setFormValues(v => {
              v.email = value;
              return {...v};
            });
          }}
          value={email}
        />
        <TextInput
          type="password"
          style={loginFormStyle.input}
          placeholder={intl.formatMessage({id: 'label.password'})}
          onChangeText={value => {
            setFormValues(v => {
              v.password = value;
              return {...v};
            });
          }}
          secureTextEntry={true}
          value={password}
        />
        <Button
          text={intl.formatMessage({id: 'label.login'})}
          onPress={() => {
            onLogin();
          }}
          disabled={isLoginActionDisabled}
          disabledContainerStyle={{opacity: 0.5}}
          containerStyle={loginFormStyle.button}
          textStyle={loginFormStyle.buttonText}
        />
      </View>
      <Button
        text="Register Now"
        onPress={() => {
          navigation.navigate('register');
        }}
      />
      <Button
        text="Forgot Password"
        onPress={() => {
          navigation.navigate('forgot-password');
        }}
      />
      {/* <Button
        text="Change Password"
        onPress={() => {
          navigation.navigate('change-password');
        }}
      /> */}
    </View>
  );
}

export default LoginForm;

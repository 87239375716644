import React from 'react';
import CheckboxSelected from '../images/CheckboxSelected.svg';
import CheckboxIcon from '../images/Checkbox.svg';
import DeSelectIcon from '../images/DeSelect.svg';
import {
  CheckboxImage,
  CheckboxContainer,
  CheckboxWrapper,
  CheckboxText,
  CheckboxSecondaryText,
} from './Styles';
import {InputWrapper} from '@unthinkable/react-input';
import {View} from '@unthinkable/react-core-components';

export const BaseCheckbox = ({
  value,
  option,
  onChangeValue,
  readOnly,
  disabled,
  indeterminate,
  ...props
}) => {
  const {styles} = props;
  const {
    checkedIcon = CheckboxSelected,
    uncheckedIcon = CheckboxIcon,
    deSelectIcon = DeSelectIcon,
  } = styles || {};

  let renderComponent = (
    <CheckboxContainer
      disabled={disabled || readOnly}
      {...(onChangeValue && {
        onPress: e => {
          onChangeValue(!value, e);
        },
      })}>
      <CheckboxImage
        source={
          indeterminate ? deSelectIcon : value ? checkedIcon : uncheckedIcon
        }
        styles={styles}
      />
    </CheckboxContainer>
  );
  if (option) {
    if (typeof option === 'string') {
      option = {value: option, label: option};
    }
    const {value: optionValue, label: optionLabel, secondaryLabel} = option;

    renderComponent = (
      <CheckboxWrapper gap={8}>
        {renderComponent}
        <View style={{flex: 1, overflow: 'hidden'}}>
          <CheckboxText numberOfLines={1} styles={styles}>
            {optionLabel || optionValue}
          </CheckboxText>
          {secondaryLabel ? (
            <CheckboxSecondaryText numberOfLines={1} styles={styles}>
              {secondaryLabel}
            </CheckboxSecondaryText>
          ) : (
            void 0
          )}
        </View>
      </CheckboxWrapper>
    );
  }
  return renderComponent;
};

export const Checkbox = props => {
  return (
    <InputWrapper {...props}>
      <BaseCheckbox {...props} />
    </InputWrapper>
  );
};

export default Checkbox;

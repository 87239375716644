import React, {useRef, useState} from 'react';
import {Row, View, Wrap} from '@unthinkable/react-core-components';
import SelectorList from './SelectorList';
import {ChipRender} from './Chip';
import {ResponsiveModal} from '@unthinkable/react-popper';
import {isMobile} from '@unthinkable/react-utils';
import {Input, InputContainer, InputWrapper} from '@unthinkable/react-input';
import {AddNewAction, ClearInputAction, Selector} from './Actions';

const AutoCompleteRightAdornments = ({
  styles,
  onCreate,
  searchValue,
  value,
  multiple,
  allowClear,
  onClearValue,
  showArrow = true,
  readOnly,
  disabled,
}) => {
  if (disabled || readOnly) {
    return null;
  }
  return (
    <Row gap={8} style={{alignItems: 'center'}}>
      {onCreate && (multiple || !value) ? (
        <AddNewAction
          styles={styles}
          onCreate={onCreate}
          searchValue={searchValue}
        />
      ) : (
        void 0
      )}
      {searchValue === undefined &&
      value &&
      !multiple &&
      allowClear !== false ? (
        <ClearInputAction styles={styles} onPress={onClearValue} />
      ) : showArrow ? (
        <Selector styles={styles} />
      ) : (
        void 0
      )}
    </Row>
  );
};

const AutoCompleteInputRender = ({
  searching,
  chip,
  chipGap = 8,
  onChangeSearch,
  maxInputWidth = 150,
  onRemove,
  inputProps,
  isFloatingLabel,
  ...props
}) => {
  {
    const {
      styles = {},
      searchValue,
      multiple,
      value,
      disabled,
      readOnly,
      getDisplayValue,
      inputRef,
    } = props;

    const renderChip = chipProps => {
      return <ChipRender {...props} {...chipProps} />;
    };

    const inputComponent = (
      <Input
        {...inputProps}
        ref={inputRef}
        styles={styles}
        value={
          multiple || searchValue !== undefined
            ? searchValue
            : getDisplayValue({value})
        }
        caretHidden={!searching}
        showSoftInputOnFocus={searching}
        onChangeText={onChangeSearch}
        maxWidth={multiple ? maxInputWidth : void 0}
        isFloatingLabel={false}
      />
    );

    let inputRender = void 0;
    if (multiple) {
      inputRender = (
        <Wrap gap={chipGap}>
          {value?.map((doc, index) => {
            return renderChip({
              value: doc,
              crossable: !(disabled || readOnly),
              onCross: () => {
                onRemove({item: doc, index});
              },
            });
          })}
          <View style={{flex: 1, justifyContent: 'center'}}>
            {inputComponent}
          </View>
        </Wrap>
      );
    } else {
      inputRender = chip && value ? renderChip({value}) : inputComponent;
    }

    return (
      <InputWrapper {...props}>
        <InputContainer {...props}>
          <Row style={{alignItems: 'center'}}>
            <View
              style={{
                flex: 1,
                overflow: 'hidden',
                ...(isFloatingLabel ? {marginTop: 12} : {}),
              }}>
              {inputRender}
            </View>
            <AutoCompleteRightAdornments {...props} />
          </Row>
        </InputContainer>
      </InputWrapper>
    );
  }
};

const BaseAutoComplete = ({
  modalPosition,
  modalPlacement = 'start',
  modalWidth,
  showModal,
  suggestions,
  renderModal,
  renderModalHeader,
  searching,
  searchingInModal,
  blurInput,
  children,
  ...props
}) => {
  const elementRef = useRef(null);
  const [elementWidth, setElementWidth] = useState(0);

  let onLayout = e => {
    const {width} = e?.nativeEvent?.layout;
    if (elementWidth !== width) {
      setElementWidth(width);
    }
  };

  if (React.isValidElement(children)) {
    children = React.cloneElement(children, {
      ...props,
    });
  }

  return (
    <>
      <ResponsiveModal
        styles={props?.styles?.modal}
        referenceElement={elementRef?.current}
        isVisible={showModal}
        position={modalPosition}
        placement={modalPlacement}
        hideModal={blurInput}
        width={!isMobile && (modalWidth || elementWidth)}
        renderHeader={renderModalHeader}>
        {renderModal || (
          <SelectorList
            {...props}
            data={suggestions}
            searching={searching && searchingInModal}
          />
        )}
      </ResponsiveModal>
      <View
        ref={elementRef}
        onLayout={onLayout}
        style={showModal ? {zIndex: 11} : void 0}>
        {children || (
          <AutoCompleteInputRender
            {...props}
            searching={searching && !searchingInModal}
          />
        )}
      </View>
    </>
  );
};

export default BaseAutoComplete;

import {sortArrayObjects} from '@unthinkable/react-utils';

export const CalculateIndex = ({
  data,
  sort,
  prevIndex,
  nextIndex,
  indexField = 'index',
}) => {
  let updatedIndex;
  let nextIndexValue = data[nextIndex]?.[indexField];
  let prevIndexValue = data[prevIndex]?.[indexField];

  if (sort === 'desc') {
    //for descending order list
    if (prevIndexValue && nextIndexValue) {
      updatedIndex = (nextIndexValue + prevIndexValue) / 2;
    } else if (prevIndexValue && !nextIndexValue) {
      updatedIndex = prevIndexValue - 1000;
    } else if (nextIndexValue && !prevIndexValue) {
      updatedIndex = nextIndexValue + 1000;
    }
  } else {
    if (prevIndexValue && nextIndexValue) {
      updatedIndex = (nextIndexValue + prevIndexValue) / 2;
    } else if (prevIndexValue && !nextIndexValue) {
      updatedIndex = prevIndexValue + 1000;
    } else if (nextIndexValue && !prevIndexValue) {
      updatedIndex = nextIndexValue - 1000;
    }
  }
  return updatedIndex;
};

const getPrevNextIndex = ({destinationIndex, sourceIndex, data}) => {
  let prevIndex = destinationIndex;
  let nextIndex = destinationIndex + 1;
  if (destinationIndex === 0) {
    prevIndex = void 0;
    nextIndex = destinationIndex;
  } else if (destinationIndex === data.length - 1) {
    nextIndex = void 0;
    prevIndex = destinationIndex;
  } else if (destinationIndex < sourceIndex) {
    nextIndex = destinationIndex;
    prevIndex = destinationIndex - 1;
  }

  return {prevIndex, nextIndex};
};

const findParentRow = (list, id, recursiveKey, rowKey) => {
  for (let i = 0; i < list.length; i++) {
    const row = list[i];
    if (row?.[rowKey] === id) {
      return row;
    }
    const children = row?.[recursiveKey];
    if (children?.length) {
      const found = findParentRow(children, id, recursiveKey, rowKey);
      if (found) {
        return found; // Return only if found, else continue looping
      }
    }
  }
};

const sortDataRecursively = ({
  data,
  id,
  sort,
  indexField,
  recursiveKey,
  rowKey,
}) => {
  const findAndSort = (list, id) => {
    for (let i = 0; i < list.length; i++) {
      const row = list[i];
      const children = row?.[recursiveKey];
      if (row?.[rowKey] === id) {
        list[i][recursiveKey] = sortArrayObjects(children, sort, indexField);
        return list;
      }
      if (children?.length) {
        const found = findAndSort(children, id);
        if (found) {
          return list; // Return only if found, else continue looping
        }
      }
    }
  };
  return findAndSort(data, id);
};

export const useDraggable = ({
  data,
  setData,
  indexField = 'index',
  sort = 'asc',
  handleDataOnDragEnd,
  updateIndexOnDragEnd,
  recursiveKey,
  rowKey,
}) => {
  const modifyDataOnDragEnd = props => {
    const {source, sourceDroppableId} = props;
    // find the parent and update the children
    if (sourceDroppableId !== 'droppable') {
      const parentRow = findParentRow(
        data,
        sourceDroppableId,
        recursiveKey,
        rowKey,
      );
      const children = parentRow?.[recursiveKey];
      const newIndex = CalculateIndex({
        data: children,
        sort,
        indexField,
        ...props,
      });
      let sourceRow = children[source.index];
      sourceRow[indexField] = newIndex || sourceRow[indexField];
      updateIndexOnDragEnd &&
        updateIndexOnDragEnd({
          row: sourceRow,
          index: source.index,
          updatedIndex: newIndex,
        });

      return sortDataRecursively({
        data,
        id: sourceDroppableId,
        sort,
        indexField,
        recursiveKey,
        rowKey,
      });
    } else {
      const newIndex = CalculateIndex({data, sort, indexField, ...props});
      let sourceRow = data[source.index];
      sourceRow[indexField] = newIndex || sourceRow[indexField];
      updateIndexOnDragEnd &&
        updateIndexOnDragEnd({
          row: sourceRow,
          index: source.index,
          updatedIndex: newIndex,
        });
      return sortArrayObjects(data, sort, indexField);
    }
  };

  const onDragEnd = result => {
    if (!data?.length) {
      return;
    }
    // handle the drag end event here
    // e.g. update the state with the new order of items
    // dropped outside the list
    const {source, destination} = result;

    if (
      !destination ||
      (source.index === destination.index &&
        source.droppableId === destination.droppableId)
    ) {
      return;
    }
    let sourceIndex = source.index;
    let destinationIndex = destination.index;
    let sourceDroppableId = source.droppableId;
    let destinationDroppableId = destination.droppableId;
    let prevIndex;
    let nextIndex;
    if (sourceDroppableId === 'droppable') {
      const prevNextIndex = getPrevNextIndex({
        destinationIndex,
        sourceIndex,
        data,
      });
      prevIndex = prevNextIndex.prevIndex;
      nextIndex = prevNextIndex.nextIndex;
    } else {
      // find the parent row recursively
      const parentRow = findParentRow(
        data,
        destinationDroppableId,
        recursiveKey,
        rowKey,
      );
      const prevNextIndex = getPrevNextIndex({
        destinationIndex,
        sourceIndex,
        data: parentRow?.[recursiveKey],
      });
      prevIndex = prevNextIndex.prevIndex;
      nextIndex = prevNextIndex.nextIndex;
    }

    const dragEndProps = {
      source,
      destination,
      prevIndex,
      nextIndex,
      sourceDroppableId,
      destinationDroppableId,
    };

    const newData = handleDataOnDragEnd
      ? handleDataOnDragEnd({
          data,
          ...dragEndProps,
        })
      : modifyDataOnDragEnd(dragEndProps);

    setData({data: newData});
  };

  return {
    onDragEnd,
  };
};

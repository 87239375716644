import {useCallback} from 'react';
import { useInvoke } from '../../../hooks';

const useTaskActions = ({navigation}) => {
  const invoke = useInvoke({
    eventSourceId: 'TASK_LIST',
    method: 'delete',
  });

  const markDelete = useCallback(
    ({row}) => {
      invoke({
        uri: `/v1/tasks/${row?._id}`,
      });
    },
    [invoke],
  );

  const onRowPress = useCallback(
    ({row}) => {
      navigation.navigate('task-edit', {row});
    },
    [navigation],
  );

  return {markDelete, onRowPress};
};

export default useTaskActions;

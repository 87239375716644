import React from 'react';
import {ScrollView} from '@unthinkable/react-core-components';
import {WithModal} from '@unthinkable/react-popper';
import {
  ActionContainer,
  ActionText,
  MoreActionImage,
  MoreActionImageContainer,
  SecondaryTitleText,
} from './styles/MoreActionStyles';
import {ConfirmPopup} from '@unthinkable/react-confirm';

const ActionRender = ({
  title,
  secondaryTitle,
  styles,
  type,
  variant,
  index,
  onPress,
  ...props
}) => {
  if (typeof title === 'function') {
    title = title(props);
  }
  if (typeof secondaryTitle === 'function') {
    secondaryTitle = secondaryTitle(props);
  }

  return (
    <ActionContainer index={index} styles={styles} onPress={onPress}>
      {title ? (
        <ActionText styles={styles} type={type} variant={variant}>
          {title}
        </ActionText>
      ) : (
        void 0
      )}
      {secondaryTitle ? (
        <SecondaryTitleText styles={styles}>
          {secondaryTitle}
        </SecondaryTitleText>
      ) : (
        void 0
      )}
    </ActionContainer>
  );
};

const MoreActions = ({
  styles,
  actions,
  modalPosition,
  modalPlacement = 'start',
  children,
  onSuccess: onSuccessProp,
  onError: onErrorProp,
  ...props
}) => {
  if (typeof actions == 'function') actions = actions(props);
  const [confirm, setConfirm] = React.useState();

  actions = actions?.filter(action => {
    if (!action) {
      return;
    }
    const {visible = true} = action;
    if (typeof visible == 'function') return visible(props);
    return visible;
  });

  const renderModal = ({hide}) => {
    return (
      <ScrollView>
        {actions.map(
          (
            {
              onPress,
              confirm,
              onSuccess = onSuccessProp,
              onError = onErrorProp,
              ...action
            },
            index,
          ) => {
            const variant = action?.variant || action?.type;

            const onActionPress = async e => {
              if (confirm?.onConfirm) {
                confirm.onConfirm(props, e);
              } else {
                try {
                  await onPress?.(props, e);
                  if (onSuccess) {
                    await onSuccess(props);
                  }
                } catch (err) {
                  if (onError) {
                    onError(err, props);
                  } else {
                    throw err;
                  }
                }
              }
            };

            return (
              <ActionRender
                {...props}
                {...action}
                key={index}
                onPress={e => {
                  if (confirm) {
                    setConfirm({
                      variant,
                      ...confirm,
                      onConfirm: onActionPress,
                    });
                  } else {
                    onActionPress(e);
                  }
                  hide && hide(e);
                }}
                styles={styles?.modal}
                index={index}
              />
            );
          },
        )}
      </ScrollView>
    );
  };

  if (!actions?.length) {
    return null;
  }

  return (
    <>
      <WithModal
        styles={styles?.modal}
        renderModal={renderModal}
        position={modalPosition}
        placement={modalPlacement}>
        {children || (
          <MoreActionImageContainer styles={styles}>
            <MoreActionImage styles={styles} />
          </MoreActionImageContainer>
        )}
      </WithModal>
      <ConfirmPopup
        {...props}
        styles={styles?.confirm}
        {...confirm}
        isVisible={!!confirm}
        onHide={() => {
          setConfirm(void 0);
        }}
      />
    </>
  );
};

export default MoreActions;

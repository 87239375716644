import {
  HeaderSelectionRender,
  SelectionRender,
  MoreActionsRender,
} from '../Renders';
import {useResponsiveColumns} from './useResponsiveColumns';
import lodash from 'lodash';
import {useCallbackRef} from '@unthinkable/react-utils';

const resolveColumnsRecursively = (columns, resolvedColumns = []) => {
  columns.forEach(column => {
    if (!column) {
      return;
    }
    if (column?.children?.length > 0) {
      resolveColumnsRecursively(column.children, resolvedColumns);
    } else {
      resolvedColumns.push(column);
    }
  });
  return resolvedColumns;
};

const filterVisibleColumns = (columns, props) => {
  const filteredColumns = [];
  columns?.forEach(column => {
    if (!column) {
      return;
    }
    let {visible = true} = column;
    if (typeof visible == 'function') {
      visible = visible(props);
    }
    if (!visible) {
      return;
    }
    if (column?.children?.length > 0) {
      const visibleChildren = filterVisibleColumns(column.children, props);
      if (visibleChildren.length > 0) {
        filteredColumns.push({...column, children: visibleChildren});
      }
    } else {
      filteredColumns.push(column);
    }
  });
  return filteredColumns;
};

export const useColumns = ({
  columns,
  moreActions,
  selection,
  offsetWidth,
  disableHeaderSelection,
  ...props
}) => {
  columns = lodash.cloneDeep(columns);

  columns = filterVisibleColumns(columns, props);
  columns = useResponsiveColumns(columns);

  if (!columns?.length) {
    return {
      columns: [],
      headerColumns: [],
    };
  }

  const moreActionsRender = useCallbackRef(props => {
    const MoreActionsComponent = MoreActionsRender(moreActions);
    return <MoreActionsComponent {...props} />;
  });

  const selectionRender = useCallbackRef(props => {
    const SelectionComponent = SelectionRender(selection);
    return <SelectionComponent {...props} />;
  });

  const headerSelectionRender = useCallbackRef(props => {
    const HeaderSelectionComponent = HeaderSelectionRender(selection);
    return (
      <HeaderSelectionComponent
        {...props}
        disableHeaderSelection={disableHeaderSelection}
      />
    );
  });

  if (selection) {
    columns.unshift({
      width: 50,
      render: selectionRender,
      header: {
        render: headerSelectionRender,
      },
    });
  }
  if (moreActions) {
    columns.push({
      width: 45,
      render: moreActionsRender,
    });
  }

  const headerColumns = columns;
  columns = resolveColumnsRecursively(columns, []);

  const columnsWidth = columns.reduce((acc, col) => {
    const {width, minWidth = 100} = col;
    const colWidth = width || minWidth;
    return acc + colWidth;
  }, 0);

  const diff = offsetWidth ? offsetWidth - columnsWidth : 0;

  const rowExceedsTable = diff < 0;
  if (rowExceedsTable) {
    columns.forEach(col => {
      const {width, minWidth = 100} = col;
      if (!width) {
        col.width = minWidth;
      }
    });
  } else if (diff > 0 && moreActions) {
    const hasAllColumnWidth = columns.every(col => col.width);
    if (hasAllColumnWidth) {
      const flexColumn = {flex: 1};
      columns.splice(columns.length - 1, 0, flexColumn);
      headerColumns.splice(headerColumns.length - 1, 0, flexColumn);
    }
  }

  return {
    columns,
    headerColumns,
    rowWidth: rowExceedsTable ? columnsWidth : undefined,
  };
};
